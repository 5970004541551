import React, { useState, useContext, useEffect } from "react";
import axios from "axios";
import Header from "../../components/Header";
import Button from "../../components/Button";
import { NotificationContext } from "../../providers/NotificationProvider";
import { useNavigate } from "react-router-dom";
import "./AddAnime.css";
const api = axios.create({
  baseURL: `${process.env.REACT_APP_API}`,
  withCredentials: true,
});
export default function AddAnime() {
  const notify = useContext(NotificationContext);
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [image, setImage] = useState(null);
  const [metaTitle, setMetaTitle] = useState("");
  const [metaDescription, setMetaDescription] = useState("");
  const [url, setUrl] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);

  // handle the url automatically
  useEffect(() => {
    const urlFromName = name
      .trim() // Remove leading and trailing spaces
      .toLowerCase()
      .replace(/\s+/g, "-") // Replace one or more spaces with a single hyphen
      .replace(/[^\w-]+/g, "-") // Replace non-alphanumeric and non-hyphen characters with a hyphen
      .replace(/--+/g, "-") // Replace two or more consecutive hyphens with a single hyphen
      .replace(/-+$/, ""); // Remove trailing hyphens
    setUrl(urlFromName);
  }, [name]);

  const handleSubmit = (e) => {
    e.preventDefault();
    // setIsLoading(true);
    // setIsDisabled(true);

    const formData = new FormData();
    if (name) {
      if (name.length < 3) {
        notify("error", "Name should be at least 3 characters");
        return;
      }
      if (name.length > 60) {
        notify("error", "Name should be at most 60 characters");
        return;
      }
      formData.append("name", name);
    } else {
      notify("error", "Name is required");
      return;
    }
    if (description) {
      formData.append("description", description);
    }
    if (url) {
      if (url.includes(" ")) {
        notify("error", "Url should not contain spaces");
        return;
      }
      formData.append("url", url);
    } else {
      notify("error", "Url is required");
      return;
    }
    if (image) {
      formData.append("image", image);
    } else {
      notify("error", "Image is required");
      return;
    }
    if (metaTitle) {
      if (metaTitle.length > 60) {
        notify("error", "Meta Title should be at most 60 characters");
        return;
      }
      formData.append("metaTitle", metaTitle);
    }
    if (metaDescription) {
      if (metaDescription.length > 160) {
        notify("error", "Meta Description should be at most 160 characters");
        return;
      }
      formData.append("metaDescription", metaDescription);
    }

    api
      .post("animes/add", formData)
      .then(() => {
        notify("success", "Anime Added Successfully");
        navigate("/animes");
      })
      .catch((error) => {
        notify("error", error.response.data.error);
      })
      .finally(() => {
        setIsLoading(false);
        setIsDisabled(false);
      });
  };

  return (
    <>
      <Header title="Add New Anime">
        <Button
          loading={isLoading}
          disable={isDisabled}
          onClick={handleSubmit}
          icon="plus"
        >
          New Anime
        </Button>
        <Button type="dark" url="/animes" icon="back" />
      </Header>
      <div className="mainlayout_content">
        <div className="demo-page">
          <div className="demo-page-navigation anime">
            <div>
              <div className="anime-review-img">
                <img
                  src={
                    image instanceof Blob
                      ? URL.createObjectURL(image)
                      : `${process.env.REACT_APP_API}/static/animes/default.webp`
                  }
                  alt=""
                />
              </div>
              <h1>{name || "Anime Name"}</h1>

              <div className="anime-content-review">
                <h2>Description</h2>
                <p>
                  {description.split("\n").map((line, i) => (
                    <span key={i}>
                      {line}
                      <br />
                    </span>
                  )) || "Write anything so this will be changed"}
                </p>{" "}
              </div>
            </div>
          </div>
          <main className="demo-page-content">
            <section>
              <div className="href-target" id="Main"></div>
              <h1>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="feather feather-layers"
                >
                  <polygon points="12 2 2 7 12 12 22 7 12 2" />
                  <polyline points="2 17 12 22 22 17" />
                  <polyline points="2 12 12 17 22 12" />
                </svg>
                Main
              </h1>
              <p>From here you could add an Anime</p>
              <div className="nice-form-group avatar">
                <label>Upload</label>
                <input
                  type="file"
                  name="image"
                  accept="image/*"
                  onChange={(e) => {
                    setImage(e.target.files[0]);
                  }}
                  required
                />
              </div>
              <div className="nice-form-group">
                <label>Anime name</label>
                <small>it should be unique</small>
                <input
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  type="text"
                  placeholder="Your name"
                  required
                />
              </div>
              <div className="nice-form-group">
                <label>Url</label>
                <small>it should be also unique</small>
                <input
                  value={url}
                  onChange={(e) => setUrl(e.target.value)}
                  type="text"
                  placeholder="the url"
                  required
                />
              </div>
              <div className="nice-form-group">
                <label>Description</label>
                <textarea
                  rows="5"
                  placeholder="Your description"
                  onChange={(e) => setDescription(e.target.value)}
                  value={description}
                ></textarea>
              </div>
            </section>
            <section>
              <div className="href-target" id="seo"></div>
              <h1>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="feather feather-layers"
                >
                  <polygon points="12 2 2 7 12 12 22 7 12 2" />
                  <polyline points="2 17 12 22 22 17" />
                  <polyline points="2 12 12 17 22 12" />
                </svg>
                SEO
              </h1>
              <p>Adding meta tags to main page</p>
              <div className="nice-form-group">
                <label>Title</label>
                <input
                  value={metaTitle}
                  onChange={(e) => setMetaTitle(e.target.value)}
                  type="text"
                  placeholder="Meta title"
                />
              </div>
              <div className="nice-form-group">
                <label>Description</label>
                <textarea
                  rows="5"
                  onChange={(e) => setMetaDescription(e.target.value)}
                  placeholder="meta desciption"
                  value={metaDescription}
                ></textarea>
              </div>
            </section>
          </main>
        </div>
      </div>
    </>
  );
}
