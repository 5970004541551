import React, { useState, useEffect, useContext, useRef } from "react";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import Header from "../../components/Header";
import { NotificationContext } from "../../providers/NotificationProvider";
import { ConfirmationContext } from "../../providers/ConfirmationProvider";
import Button from "../../components/Button";
import { getName, getCodes } from "country-list";

const api = axios.create({
  baseURL: `${process.env.REACT_APP_API}`,
});

export default function EditAccount() {
  const initialData = useRef();
  const notify = useContext(NotificationContext);
  const Navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [gender, setGender] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const [avatar, setAvatar] = useState(null);
  const [avatarUrl, setAvatarUrl] = useState(null); // This is the preview image url
  const { url } = useParams(); // Get the id from the URL
  const [cover, setCover] = useState(null);
  const [coverUrl, setCoverUrl] = useState(null); // This is the preview image url
  const [bio, setBio] = useState("");
  const [birthday, setBirthday] = useState("");
  const [phone, setPhone] = useState("");
  const [location, setLocation] = useState("");
  const [role, setRole] = useState("");
  const [metaTitle, setMetaTitle] = useState("");
  const [metaDescription, setMetaDescription] = useState("");
  const [username, setUsername] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [theurl, setTheUrl] = useState("");
  const { confirm, closeConfirmation } = useContext(ConfirmationContext);
  const [isLoading, setIsLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const countryCodes = getCodes();

  // ...

  useEffect(() => {
    api
      .get(`/accounts/${url}`) // Use the id in the API endpoint
      .then((response) => {
        const data = response.data;
        Object.keys(data).forEach((key) => {
          if (data[key] === null) {
            data[key] = "";
          }
        });
        console.log(data);
        initialData.current = {
          username: data.username,
          url: data.url,
          email: data.email,
          avatar: data.avatar,
          cover: data.cover,
          role: data.role,
          active: data.active,
          bio: data.bio,
          birthday: data.birthday ? data.birthday.split("T")[0] : null,
          location: data.location,
          gender: data.gender,
          createdAt: data.createdAt,
          updatedAt: data.updatedAt,
          metaTitle: data.metaTitle,
          metaDescription: data.metaDescription,
          firstName: data.firstName,
          lastName: data.lastName,
          phone: data.phone,
        };

        setFirstName(data.firstName);
        setLastName(data.lastName);
        setTheUrl(data.url);
        setUsername(data.username);
        setEmail(data.email);
        setTheUrl(data.url);
        setAvatarUrl(
          process.env.REACT_APP_API +
            data.avatar.replace(/uploads[/\\]/, "/static/")
        );
        setCoverUrl(
          process.env.REACT_APP_API +
            data.cover.replace(/uploads[/\\]/, "/static/")
        );
        setRole(data.role);
        setBio(data.bio);
        setBirthday(data.birthday && data.birthday.split("T")[0]);
        setPhone(data.phone);
        setLocation(data.location);
        setMetaDescription(data.metaDescription);
        setMetaTitle(data.metaTitle);
        setGender(data.gender);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [url]);

  const handleDelete = (e) => {
    e.preventDefault();
    setIsLoading(true);
    setIsDisabled(true);

    const token = localStorage.getItem("token"); // Get the token from local storage
    confirm(
      "Are you sure?",
      "when you delete a user you will delete all his videos, tags and categories.",
      () => {
        api
          .delete(`/accounts/${url}`, {
            headers: {
              Authorization: `Bearer ${token}`, // Add the token to the request headers
            },
          })
          .then((response) => {
            notify("success", "Account Deleted Successfully");
            Navigate("/accounts");
            closeConfirmation();
            // Redirect to another page or update the state to reflect the deletion
          })
          .catch((error) => {
            notify("error", error.response.data.error);
            closeConfirmation();
          })
          .finally(() => {
            setIsLoading(false);
            setIsDisabled(false);
          });
      }
    );
  };

  const handleUpdate = (e) => {
    e.preventDefault();
    setIsLoading(true);
    setIsDisabled(true);

    if (password !== passwordConfirmation) {
      notify("error", "Passwords don't match");
      setIsLoading(false);
      setIsDisabled(false);
      return;
    }
    // Create a new FormData object
    const updatedData = new FormData();

    if (username !== initialData.current?.username)
      updatedData.append("username", username);
    if (email !== initialData.current?.email)
      updatedData.append("email", email);
    if (gender !== initialData.current?.gender)
      updatedData.append("gender", gender);
    if (avatar !== null) updatedData.append("avatar", avatar, avatar.name);
    if (cover !== null) updatedData.append("cover", cover, cover.name);
    if (bio !== initialData.current?.bio) updatedData.append("bio", bio);
    if (birthday !== initialData.current?.birthday) {
      const birthdayDate = new Date(birthday);
      if (!isNaN(birthdayDate.getTime())) {
        // Check if birthdayDate is a valid date
        updatedData.append("birthday", birthdayDate.toISOString());
      } else {
        console.error("Invalid birthday value:", birthday);
      }
    }
    if (phone !== initialData.current?.phone)
      updatedData.append("phone", phone);
    if (location !== initialData.current?.location)
      updatedData.append("location", location);
    if (role !== initialData.current?.role) updatedData.append("role", role);
    if (metaTitle !== initialData.current?.metaTitle)
      updatedData.append("metaTitle", metaTitle);
    if (metaDescription !== initialData.current?.metaDescription)
      updatedData.append("metaDescription", metaDescription);
    if (password !== "") updatedData.append("password", password);
    if (firstName !== initialData.current?.firstName)
      updatedData.append("firstName", firstName);
    if (lastName !== initialData.current?.lastName)
      updatedData.append("lastName", lastName);
    if (theurl !== initialData.current?.url) updatedData.append("url", theurl);

    if (
      !updatedData.has("username") &&
      !updatedData.has("email") &&
      !updatedData.has("gender") &&
      !updatedData.has("avatar") &&
      !updatedData.has("cover") &&
      !updatedData.has("bio") &&
      !updatedData.has("birthday") &&
      !updatedData.has("phone") &&
      !updatedData.has("location") &&
      !updatedData.has("role") &&
      !updatedData.has("metaTitle") &&
      !updatedData.has("metaDescription") &&
      !updatedData.has("password") &&
      !updatedData.has("firstName") &&
      !updatedData.has("lastName") &&
      !updatedData.has("url")
    ) {
      notify("error", "No changes detected");
      setIsLoading(false);
      setIsDisabled(false);
      return;
    }
    console.log(Object.fromEntries(updatedData));
    api
      .put(`/accounts/${url}`, updatedData,{ withCredentials: true })
      .then((response) => {
        notify("success", "Account Updated Successfully");
      })
      .catch((error) => {
        notify("error", error.response.data.error);
      })
      .finally(() => {
        setIsLoading(false);
        setIsDisabled(false);
      });
  };

  return (
    <>
      <Header title={`Editing ${initialData.current?.username}'s account`}>
        <Button
          loading={isLoading}
          disable={isDisabled}
          onClick={handleUpdate}
          icon="add-account"
        >
          Update Account
        </Button>{" "}
        <Button onClick={handleDelete} icon="delete" type="dangerous">
          Delete this account
        </Button>
        <Button type="dark" icon="back" url="/accounts" />
      </Header>
      <div className="mainlayout_content">
        <div className="demo-page">
          <div className="demo-page-navigation">
            <nav>
              <ul>
                <li>
                  <a href="#Main">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="feather feather-layers"
                    >
                      <polygon points="12 2 2 7 12 12 22 7 12 2" />
                      <polyline points="2 17 12 22 22 17" />
                      <polyline points="2 12 12 17 22 12" />
                    </svg>
                    Main
                  </a>
                </li>
                <li>
                  <a href="#password">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="feather feather-lock"
                    >
                      <rect
                        x="3"
                        y="11"
                        width="18"
                        height="11"
                        rx="2"
                        ry="2"
                      ></rect>
                      <path d="M7 11V7a5 5 0 0 1 10 0v4"></path>
                    </svg>
                    Change Password
                  </a>
                </li>
              </ul>
            </nav>
          </div>
          <main className="demo-page-content">
            <section>
              <div className="href-target" id="Main"></div>
              <h1>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="feather feather-layers"
                >
                  <polygon points="12 2 2 7 12 12 22 7 12 2" />
                  <polyline points="2 17 12 22 22 17" />
                  <polyline points="2 12 12 17 22 12" />
                </svg>
                Main
              </h1>
              <p>From here you could add an account</p>
              <div className="nice-form-group avatar">
                <label>Upload Avatar</label>
                {avatarUrl && (
                  <img
                    src={avatarUrl}
                    alt="Avatar Preview"
                    style={{
                      borderRadius: "50%", // This makes the image circular
                      width: "50px",
                      height: "50px",
                      objectFit: "cover",
                    }}
                  />
                )}
                <input
                  type="file"
                  accept="image/*"
                  name="avatar"
                  onChange={(e) => {
                    setAvatar(e.target.files[0]);
                    setAvatarUrl(URL.createObjectURL(e.target.files[0]));
                  }}
                />
              </div>
              <div className="nice-form-group cover">
                <label>Upload Cover</label>
                {coverUrl && <img src={coverUrl} alt="Avatar Preview" />}
                <input
                  type="file"
                  name="cover"
                  onChange={(e) => {
                    setCover(e.target.files[0]);
                    setCoverUrl(URL.createObjectURL(e.target.files[0]));
                  }}
                />
              </div>
              <div className="nice-form-group">
                <label>Username</label>
                <small>it should be unique and also just small letters</small>
                <input
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  type="text"
                  placeholder="Your name"
                  required
                />
              </div>
              <div className="nice-form-group">
                <label>url</label>
                <small>it should be unique and also just small letters</small>
                <input
                  value={theurl}
                  onChange={(e) => setTheUrl(e.target.value)}
                  type="text"
                  placeholder="Your name"
                  required
                />
              </div>
              <div className="nice-form-group">
                <label>Email</label>
                <input
                  type="email"
                  placeholder="Your email"
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                  className="icon-left"
                  required
                />
              </div>

              <div className="nice-form-group">
                <label>Password</label>
                <input
                  type="password"
                  placeholder="Your password"
                  className="icon-left"
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
              </div>
              <div className="nice-form-group">
                <label>Repeat Password</label>
                <input
                  type="password"
                  placeholder="Repeat your password"
                  className="icon-left"
                  onChange={(e) => setPasswordConfirmation(e.target.value)}
                  required
                />
              </div>

              <div className="nice-form-group">
                <label>Select a Role</label>
                <select
                  name="role"
                  onChange={(e) => setRole(e.target.value)}
                  value={role}
                >
                  <option>Please select a value</option>
                  <option value="USER">User</option>
                  <option value="MODERATOR">Moderator</option>
                  <option value="ADMIN">Admin</option>
                </select>
              </div>
            </section>
            <section>
              <div className="href-target" id="personal"></div>
              <h1>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="feather feather-layers"
                >
                  <polygon points="12 2 2 7 12 12 22 7 12 2" />
                  <polyline points="2 17 12 22 22 17" />
                  <polyline points="2 12 12 17 22 12" />
                </svg>
                Personal
              </h1>
              <p>Personal information</p>
              <div className="nice-form-group">
                <label>First Name</label>
                <input
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  type="text"
                  placeholder="Your name"
                  required
                />
              </div>
              <div className="nice-form-group">
                <label>Last Name</label>
                <input
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  type="text"
                  placeholder="Your name"
                  required
                />
              </div>
              <div className="nice-form-group">
                <label>Date</label>
                <input
                  value={birthday}
                  onChange={(e) => setBirthday(e.target.value)}
                  type="date"
                />
              </div>

              <div className="nice-form-group">
                <label>Phone Number</label>
                <input
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  type="tel"
                  className="icon-left"
                  placeholder="Your Phone number"
                  required
                />
              </div>

              <div className="nice-form-group">
                <label>location</label>
                <select
                  name="location"
                  onChange={(e) => setLocation(e.target.value)}
                  value={location}
                >
                  <option value={location}>{getName(location)}</option>
                  {countryCodes
                    .filter((code) => code !== location)
                    .map((code) => (
                      <option key={code} value={code}>
                        {getName(code)}
                      </option>
                    ))}
                </select>
              </div>

              <div className="nice-form-group">
                <label>Select a Gender</label>
                <select
                  name="gender"
                  onChange={(e) => setGender(e.target.value)}
                  value={gender}
                >
                  <option>Please select a value</option>
                  <option value="M">Male</option>
                  <option value="F">Female</option>
                  <option value="O">Other</option>
                </select>
              </div>
              <div className="nice-form-group">
                <label>Bio</label>
                <textarea
                  rows="5"
                  onChange={(e) => setBio(e.target.value)}
                  placeholder="Tell us about yourself"
                  value={bio}
                ></textarea>
              </div>
            </section>
            <section>
              <div className="href-target" id="seo"></div>
              <h1>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="feather feather-layers"
                >
                  <polygon points="12 2 2 7 12 12 22 7 12 2" />
                  <polyline points="2 17 12 22 22 17" />
                  <polyline points="2 12 12 17 22 12" />
                </svg>
                SEO
              </h1>
              <p>Adding meta tags to main page</p>
              <div className="nice-form-group">
                <label>Title</label>
                <input
                  value={metaTitle}
                  onChange={(e) => setMetaTitle(e.target.value)}
                  type="text"
                  placeholder="Meta title"
                />
              </div>
              <div className="nice-form-group">
                <label>Description</label>
                <textarea
                  rows="5"
                  onChange={(e) => setMetaDescription(e.target.value)}
                  placeholder="meta desciption"
                  value={metaDescription}
                ></textarea>
              </div>
            </section>
          </main>
        </div>
      </div>
    </>
  );
}
