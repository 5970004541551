import React, { useState, useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import axios from "axios";

const api = axios.create({
  baseURL: `${process.env.REACT_APP_API}`,
  withCredentials: true,
});

export default function AuthenticatedApp({ children }) {
  const [isLoading, setLoading] = useState(true);
  const [isAuthenticated, setAuthenticated] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    api
      .get("/accounts/verify") // Change this to your verify endpoint
      .then((response) => {
        localStorage.setItem("avatar", response.data.data.avatar);
        localStorage.setItem("role", response.data.data.role);
        localStorage.setItem("username", response.data.data.username);
        setAuthenticated(true);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  if (isLoading) {
    return null;
  }

  if (!isAuthenticated) {
    navigate("/login");
    return null;
  }

  return <Outlet />;
}
