import React, { createContext, useState, useCallback } from "react";
import Notification from "./Notification";

export const NotificationContext = createContext();

export const NotificationProvider = ({ children }) => {
  const [notification, setNotification] = useState({
    type: "",
    message: "",
    show: false,
  });

  const notify = useCallback((type, message) => {
    setNotification({ type, message, show: true, key: Math.random() });
    setTimeout(
      () => setNotification((prev) => ({ ...prev, show: false })),
      3000
    );
  }, []);

  return (
    <NotificationContext.Provider value={notify}>
      {notification.show && (
        <Notification
          key={notification.key}
          type={notification.type}
          message={notification.message}
        />
      )}
      {children}
    </NotificationContext.Provider>
  );
};
