import React, { useState, useContext, useEffect } from "react";
import axios from "axios";
import Header from "../../components/Header";
import { NotificationContext } from "../../providers/NotificationProvider";
import { useNavigate } from "react-router-dom";
import Button from "../../components/Button";
import { getName, getCodes } from "country-list";

const api = axios.create({
  baseURL: `${process.env.REACT_APP_API}`,
});

export default function AddAccount() {
  const notify = useContext(NotificationContext);
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const [gender, setGender] = useState("");
  // IMAGES STATES COVER AND AVATAR //
  const [avatar, setAvatar] = useState(null);
  const [avatarUrl, setAvatarUrl] = useState(
    process.env.REACT_APP_API + "/static/avatar/default.webp"
  ); // This is the preview image url
  const [coverUrl, setCoverUrl] = useState(
    process.env.REACT_APP_API + "/static/cover/default.webp"
  ); // This is the preview image url

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [metaTitle, setMetaTitle] = useState("");
  const [metaDescription, setMetaDescription] = useState("");
  const [cover, setCover] = useState(null);
  const [url, setUrl] = useState("");
  const [bio, setBio] = useState("");
  const [role, setRole] = useState("");
  const [birthday, setBirthday] = useState("");
  const [location, setLocation] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const countryCodes = getCodes();
  // handle the url automatically
  useEffect(() => {
    const urlFromName = username
      .trim() // Remove leading and trailing spaces
      .toLowerCase()
      .replace(/\s+/g, "-") // Replace one or more spaces with a single hyphen
      .replace(/[^\w-]+/g, "-") // Replace non-alphanumeric and non-hyphen characters with a hyphen
      .replace(/--+/g, "-") // Replace two or more consecutive hyphens with a single hyphen
      .replace(/-+$/, ""); // Remove trailing hyphens
    setUrl(urlFromName);
  }, [username]);

  useEffect(() => {
    api.get("/location").then((response) => {
      setLocation(response.data.country);
    });
  }, []);
  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    setIsDisabled(true);
    const formData = new FormData();
    formData.append("username", username);
    formData.append("email", email);
    formData.append("password", password);
    formData.append("gender", gender);
    formData.append("avatar", avatar);
    formData.append("cover", cover);
    formData.append("firstName", firstName);
    formData.append("lastName", lastName);
    formData.append("phone", phone);
    formData.append("metaTitle", metaTitle);
    formData.append("metaDescription", metaDescription);
    formData.append("url", url);
    formData.append("bio", bio);
    formData.append("role", role);
    const birthdayDate = new Date(birthday);
    formData.append("birthday", birthdayDate.toISOString());
    formData.append("location", location);
    if (password !== passwordConfirmation) {
      notify("error", "Passwords do not match");
      setIsLoading(false);
      setIsDisabled(false);
      return;
    }
    console.log(Object.fromEntries(formData));

    api
      .post("/accounts/add", formData)
      .then((response) => {
        notify("success", "Account Added Successfully");
        navigate("/accounts");
      })
      .catch((error) => {
        notify("error", error.response.data.error);
      })
      .finally(() => {
        setIsLoading(false); // set isLoading to false after the request finishes
        setIsDisabled(false);
      });
  };

  return (
    <>
      <Header title="Create an Account">
        <Button
          loading={isLoading}
          disable={isDisabled}
          onClick={handleSubmit}
          icon="add-account"
        >
          New Account
        </Button>
        <Button type="dark" url="/accounts" icon="back" />
      </Header>
      <div className="mainlayout_content">
        <div className="demo-page">
          <div className="demo-page-navigation">
            <nav>
              <ul>
                <li>
                  <a href="#Main">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="feather feather-layers"
                    >
                      <polygon points="12 2 2 7 12 12 22 7 12 2" />
                      <polyline points="2 17 12 22 22 17" />
                      <polyline points="2 12 12 17 22 12" />
                    </svg>
                    Main
                  </a>
                </li>
                <li>
                  <a href="#personal">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="feather feather-layers"
                    >
                      <polygon points="12 2 2 7 12 12 22 7 12 2" />
                      <polyline points="2 17 12 22 22 17" />
                      <polyline points="2 12 12 17 22 12" />
                    </svg>
                    Personal
                  </a>
                </li>
                <li>
                  <a href="#seo">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="feather feather-layers"
                    >
                      <polygon points="12 2 2 7 12 12 22 7 12 2" />
                      <polyline points="2 17 12 22 22 17" />
                      <polyline points="2 12 12 17 22 12" />
                    </svg>
                    SEO
                  </a>
                </li>
              </ul>
            </nav>
          </div>
          <main className="demo-page-content">
            <section>
              <div className="href-target" id="Main"></div>
              <h1>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="feather feather-layers"
                >
                  <polygon points="12 2 2 7 12 12 22 7 12 2" />
                  <polyline points="2 17 12 22 22 17" />
                  <polyline points="2 12 12 17 22 12" />
                </svg>
                Main
              </h1>
              <p>From here you could add an account</p>
              <div className="nice-form-group avatar">
                <label>Upload Avatar</label>
                {avatarUrl && (
                  <img
                    src={avatarUrl}
                    alt="Avatar Preview"
                    style={{
                      borderRadius: "50%", // This makes the image circular
                      width: "50px",
                      height: "50px",
                      objectFit: "cover",
                    }}
                  />
                )}
                <input
                  type="file"
                  accept="image/*"
                  name="avatar"
                  onChange={(e) => {
                    setAvatar(e.target.files[0]);
                    setAvatarUrl(URL.createObjectURL(e.target.files[0]));
                  }}
                />
              </div>
              <div className="nice-form-group cover">
                <label>Upload Cover</label>
                {coverUrl && <img src={coverUrl} alt="Avatar Preview" />}
                <input
                  type="file"
                  name="cover"
                  onChange={(e) => {
                    setCover(e.target.files[0]);
                    setCoverUrl(URL.createObjectURL(e.target.files[0]));
                  }}
                />
              </div>
              <div className="nice-form-group">
                <label>Username</label>
                <small>it should be unique and also just small letters</small>
                <input
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  type="text"
                  placeholder="Your name"
                  required
                />
              </div>
              <div className="nice-form-group">
                <label>url</label>
                <small>it should be unique and also just small letters</small>
                <input
                  value={url}
                  onChange={(e) => setUrl(e.target.value)}
                  type="text"
                  placeholder="Your name"
                  required
                />
              </div>
              <div className="nice-form-group">
                <label>Email</label>
                <input
                  type="email"
                  placeholder="Your email"
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                  className="icon-left"
                  required
                />
              </div>

              <div className="nice-form-group">
                <label>Password</label>
                <input
                  type="password"
                  placeholder="Your password"
                  className="icon-left"
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
              </div>
              <div className="nice-form-group">
                <label>Repeat Password</label>
                <input
                  type="password"
                  placeholder="Repeat your password"
                  className="icon-left"
                  onChange={(e) => setPasswordConfirmation(e.target.value)}
                  required
                />
              </div>

              <div className="nice-form-group">
                <label>Select a Role</label>
                <select
                  name="role"
                  onChange={(e) => setRole(e.target.value)}
                  value={role}
                >
                  <option>Please select a value</option>
                  <option value="USER">User</option>
                  <option value="MODERATOR">Moderator</option>
                  <option value="ADMIN">Admin</option>
                </select>
              </div>
            </section>
            <section>
              <div className="href-target" id="personal"></div>
              <h1>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="feather feather-layers"
                >
                  <polygon points="12 2 2 7 12 12 22 7 12 2" />
                  <polyline points="2 17 12 22 22 17" />
                  <polyline points="2 12 12 17 22 12" />
                </svg>
                Personal
              </h1>
              <p>Personal information</p>
              <div className="nice-form-group">
                <label>First Name</label>
                <input
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  type="text"
                  placeholder="Your name"
                  required
                />
              </div>
              <div className="nice-form-group">
                <label>Last Name</label>
                <input
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  type="text"
                  placeholder="Your name"
                  required
                />
              </div>
              <div className="nice-form-group">
                <label>Date</label>
                <input
                  value={birthday}
                  onChange={(e) => setBirthday(e.target.value)}
                  type="date"
                />
              </div>

              <div className="nice-form-group">
                <label>Phone Number</label>
                <input
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  type="tel"
                  className="icon-left"
                  placeholder="Your Phone number"
                  required
                />
              </div>

              <div className="nice-form-group">
                <label>location</label>
                <select
                  name="location"
                  onChange={(e) => setLocation(e.target.value)}
                  value={location}
                >
                  <option value={location}>{getName(location)}</option>
                  {countryCodes
                    .filter((code) => code !== location)
                    .map((code) => (
                      <option key={code} value={code}>
                        {getName(code)}
                      </option>
                    ))}
                </select>
              </div>

              <div className="nice-form-group">
                <label>Select a Gender</label>
                <select
                  name="gender"
                  onChange={(e) => setGender(e.target.value)}
                  value={gender}
                >
                  <option>Please select a value</option>
                  <option value="M">Male</option>
                  <option value="F">Female</option>
                  <option value="O">Other</option>
                </select>
              </div>
              <div className="nice-form-group">
                <label>Bio</label>
                <textarea
                  rows="5"
                  onChange={(e) => setBio(e.target.value)}
                  placeholder="Tell us about yourself"
                  value={bio}
                ></textarea>
              </div>
            </section>
            <section>
              <div className="href-target" id="seo"></div>
              <h1>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="feather feather-layers"
                >
                  <polygon points="12 2 2 7 12 12 22 7 12 2" />
                  <polyline points="2 17 12 22 22 17" />
                  <polyline points="2 12 12 17 22 12" />
                </svg>
                SEO
              </h1>
              <p>Adding meta tags to main page</p>
              <div className="nice-form-group">
                <label>Title</label>
                <input
                  value={metaTitle}
                  onChange={(e) => setMetaTitle(e.target.value)}
                  type="text"
                  placeholder="Meta title"
                />
              </div>
              <div className="nice-form-group">
                <label>Description</label>
                <textarea
                  rows="5"
                  onChange={(e) => setMetaDescription(e.target.value)}
                  placeholder="meta desciption"
                  value={metaDescription}
                ></textarea>
              </div>
            </section>
          </main>
        </div>
      </div>
    </>
  );
}
