import React, { useState } from "react";
import Button from "../components/Button";
import "./ReadMore.css";
export default function ReadMore({ children, maxCharacterCount = 200 }) {
  const text = String(children);
  const [isTruncated, setIsTruncated] = useState(true);

  const lastSpace = text.lastIndexOf(" ", maxCharacterCount);
  const resultString = isTruncated ? text.slice(0, lastSpace) : text;
  if (text.length === 0) {
    return <p>their is no description</p>;
  }
  if (text.length <= maxCharacterCount) {
    return <p>{text}</p>;
  }

  return (
    <p className="readmore">
      {resultString}
      <Button onClick={() => setIsTruncated(!isTruncated)}>
        {isTruncated ? "Read more" : " Show less"}
      </Button>
    </p>
  );
}
