import React, { useState, useEffect, useContext, useRef } from "react";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import Header from "../../components/Header";
import { NotificationContext } from "../../providers/NotificationProvider";
import { ConfirmationContext } from "../../providers/ConfirmationProvider";
import Button from "../../components/Button";
const api = axios.create({
  baseURL: `${process.env.REACT_APP_API}`,
});
export default function EditCategory() {
  const { url } = useParams();
  const initialData = useRef();
  const navigation = useNavigate();
  const notify = useContext(NotificationContext);
  const { confirm, closeConfirmation } = useContext(ConfirmationContext);
  const Navigate = useNavigate();
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [image, setImage] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);
  const [metaTitle, setMetaTitle] = useState("");
  const [metaDescription, setMetaDescription] = useState("");
  const [theurl, setTheUrl] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    setIsDisabled(true);
    api
      .get(`/categories/${url}`)
      .then((response) => {
        const data = response.data;
        initialData.current = {
          name: data.name,
          url: data.url,
          description: data.description,
          image: data.image,
          metaTitle: data.metaTitle,
          metaDescription: data.metaDescription,
        };

        setName(data.name);
        setDescription(data.description);
        setMetaTitle(data.metaTitle);
        setMetaDescription(data.metaDescription);
        setTheUrl(data.url);
        setImageUrl(
          process.env.REACT_APP_API +
            data.image.replace(/uploads[/\\]/, "/static/")
        );
      })
      .catch((error) => {
        notify("error", error.response.data.error);
      })
      .finally(() => {
        setIsLoading(false);
        setIsDisabled(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url]);

  // update the categorie
  const handleUpdate = (e) => {
    e.preventDefault();
    setIsLoading(true);
    setIsDisabled(true);

    // Create a new FormData object
    const updatedData = new FormData();
    if (name !== initialData.current?.name) updatedData.append("name", name);
    if (theurl !== initialData.current?.url && image === null)
      updatedData.append("url", theurl);
    if (image !== null) {
      updatedData.append("url", theurl);
      updatedData.append("image", image);
    }
    if (description !== initialData.current?.description)
      updatedData.append("description", description);
    if (metaTitle !== initialData.current?.metaTitle)
      updatedData.append("metaTitle", metaTitle);
    if (metaDescription !== initialData.current?.metaDescription)
      updatedData.append("metaDescription", metaDescription);
    if (Array.from(updatedData.entries()).length === 0) {
      notify("error", "No changes detected");
      setIsLoading(false);
      setIsDisabled(false);
      return;
    }

    api
      .put(`/categories/${url}`, updatedData)
      .then((response) => {
        if (theurl !== initialData.current?.url) {
          navigation(`/categories`);
        }
        notify("success", "Category Updated Successfully");
      })
      .catch((error) => {
        notify("error", error.response.data.error);
      })
      .finally(() => {
        setIsLoading(false);
        setIsDisabled(false);
      });
  };

  // delete the category
  const handleDelete = (e) => {
    e.preventDefault();
    setIsLoading(true);
    setIsDisabled(true);

    confirm(
      "Are you sure?",
      "when you delete a category, all associated data will also be deleted.",
      () => {
        api
          .delete(`/categories/${url}`)
          .then((response) => {
            notify("success", "Category Deleted Successfully");
            Navigate("/categories");
            closeConfirmation();
            // Redirect to another page or update the state to reflect the deletion
          })
          .catch((error) => {
            notify("error", error.response.data.error);
            closeConfirmation();
          })
          .finally(() => {
            setIsLoading(false);
            setIsDisabled(false);
          });
      }
    );
  };
  return (
    <>
      <Header title={`Edit ${name} category`}>
        <Button onClick={handleDelete} icon="delete" type="dangerous">
          Delete this account
        </Button>
        <Button loading={isLoading} disable={isDisabled} onClick={handleUpdate}>
          Edit Category
        </Button>
        <Button type="dark" url="/categories" icon="back" />
      </Header>
      <div className="mainlayout_content">
        <div className="demo-page">
          <div className="demo-page-navigation category">
            <div>
              <div className="category-review-img">
                <img
                  src={
                    image
                      ? URL.createObjectURL(image)
                      : imageUrl
                      ? imageUrl
                      : `${process.env.REACT_APP_API}/static/categories/default.webp`
                  }
                  alt=""
                />
                <h1>{name || "Category Name"}</h1>
              </div>
              <div className="category-content-review">
                <h2>Description</h2>
                <p>
                  {description.split("\n").map((line, i) => (
                    <span key={i}>
                      {line}
                      <br />
                    </span>
                  )) || "Write anything so this will be changed"}
                </p>{" "}
              </div>
            </div>
          </div>
          <main className="demo-page-content">
            <section>
              <div className="href-target" id="Main"></div>
              <h1>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="feather feather-layers"
                >
                  <polygon points="12 2 2 7 12 12 22 7 12 2" />
                  <polyline points="2 17 12 22 22 17" />
                  <polyline points="2 12 12 17 22 12" />
                </svg>
                Main
              </h1>
              <p>From here you could add a category</p>
              <div className="nice-form-group avatar">
                <label>Upload</label>
                <input
                  type="file"
                  accept="image/*"
                  name="image"
                  onChange={(e) => {
                    setImage(e.target.files[0]);
                  }}
                  required
                />
              </div>
              <div className="nice-form-group">
                <label>categorie name</label>
                <small>it should be unique</small>
                <input
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  type="text"
                  placeholder="Your name"
                  required
                />
              </div>
              <div className="nice-form-group">
                <label>Url</label>
                <small>it should be also unique</small>
                <input
                  value={theurl}
                  onChange={(e) => setTheUrl(e.target.value)}
                  type="text"
                  placeholder="the url"
                  required
                />
              </div>
              <div className="nice-form-group">
                <label>Description</label>
                <textarea
                  rows="5"
                  placeholder="Your description"
                  onChange={(e) => setDescription(e.target.value)}
                  value={description}
                ></textarea>
              </div>
            </section>
            <section>
              <div className="href-target" id="seo"></div>
              <h1>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="feather feather-layers"
                >
                  <polygon points="12 2 2 7 12 12 22 7 12 2" />
                  <polyline points="2 17 12 22 22 17" />
                  <polyline points="2 12 12 17 22 12" />
                </svg>
                SEO
              </h1>
              <p>Adding meta tags to main page</p>
              <div className="nice-form-group">
                <label>Title</label>
                <input
                  value={metaTitle}
                  onChange={(e) => setMetaTitle(e.target.value)}
                  type="text"
                  placeholder="Meta title"
                />
              </div>
              <div className="nice-form-group">
                <label>Description</label>
                <textarea
                  rows="5"
                  onChange={(e) => setMetaDescription(e.target.value)}
                  placeholder="meta desciption"
                  value={metaDescription}
                ></textarea>
              </div>
            </section>
          </main>
        </div>
      </div>
      ;
    </>
  );
}
