import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./Button.css";
const Button = ({ type, onClick, url, icon, loading, disable, children }) => {
  const [isLoading, setIsLoading] = useState(loading || false);
  const [isDisabled, setIsDisabled] = useState(disable || false);
  const Navigate = useNavigate();
  useEffect(() => {
    setIsLoading(loading);
    setIsDisabled(disable);
  }, [loading, disable]);

  const handleClick = async (e) => {
    if (isDisabled) {
      return;
    }
    e.preventDefault();
    if (onClick) {
      await onClick(e);
    }
    if (url) {
      if (url.startsWith("http")) {
        window.location.href = url;
      } else {
        Navigate(url);
      }
    }
  };
  // This is the icon switcher
  if (icon) {
    switch (icon) {
      case "add-account":
        icon = (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="feather feather-user-plus"
          >
            <path d="M16 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
            <circle cx="8.5" cy="7" r="4"></circle>
            <line x1="20" y1="8" x2="20" y2="14"></line>
            <line x1="23" y1="11" x2="17" y2="11"></line>
          </svg>
        );
        break;
      case "back":
        icon = (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="feather feather-arrow-left"
          >
            <line x1="19" y1="12" x2="5" y2="12"></line>
            <polyline points="12 19 5 12 12 5"></polyline>
          </svg>
        );
        break;
      case "delete":
        icon = (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="feather feather-trash-2"
          >
            <polyline points="3 6 5 6 21 6"></polyline>
            <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
            <line x1="10" y1="11" x2="10" y2="17"></line>
            <line x1="14" y1="11" x2="14" y2="17"></line>
          </svg>
        );
        break;
      case "video":
        icon = (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="feather feather-video"
          >
            <polygon points="23 7 16 12 23 17 23 7"></polygon>
            <rect x="1" y="5" width="15" height="14" rx="2" ry="2"></rect>
          </svg>
        );
        break;
      case "plus":
        icon = (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="feather feather-plus-circle"
          >
            <circle cx="12" cy="12" r="10"></circle>
            <line x1="12" y1="8" x2="12" y2="16"></line>
            <line x1="8" y1="12" x2="16" y2="12"></line>
          </svg>
        );
        break;
      default:
        icon = null;
        break;
    }
  } else {
    icon = null;
  }
  const buttonClass =
    type === "serious"
      ? "btn-serious"
      : type === "dangerous"
      ? "btn-dangerous"
      : type === "dark"
      ? "btn-dark"
      : "btn-default";
  return (
    <button
      type={type}
      className={`to-repo ${buttonClass}`}
      onClick={(e) => handleClick(e)}
      disabled={isDisabled}
    >
      {isLoading ? (
        "Loading..."
      ) : (
        <>
          {icon ? icon : ""}
          {children ? <div>{children}</div> : ""}
        </>
      )}
    </button>
  );
};

export default Button;
