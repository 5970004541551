import React from "react";
import "./mainLog.css";
export default function log() {
  return (
    <div className="log">
      <div className="header">
        <h1>Log</h1>
        <p>Updated at: 4/4/2024 5:08</p>
      </div>
      <div className="terminal">
        {Array.from({ length: 1000 }, (_, i) => (
          <p key={i}>Line {i + 1}</p>
        ))}
      </div>
    </div>
  );
}
