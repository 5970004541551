import React from "react";
import "./Notification.css"; // Import your CSS styles

export default function Notification({ type, message }) {
  let className = "notification";
  switch (type) {
    case "error":
      className += " error";

      break;
    case "success":
      className += " success";

      break;
    case "info":
    default:
      className += " info";
      break;
  }

  return <div className={className}>{message}</div>;
}
