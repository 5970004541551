import React, { useState, useEffect, useContext, useCallback } from "react";
import { useRef } from "react";
import axios from "axios";
import Header from "../../components/Header";
import Button from "../../components/Button";
import { useParams, useNavigate } from "react-router-dom";
import "./AddVideo.css";
import "@vidstack/react/player/styles/default/theme.css";
import "@vidstack/react/player/styles/default/layouts/video.css";
import {
  MediaPlayer,
  MediaProvider,
  Poster,
  MediaPlayerInstance,
  useStore,
  useMediaStore,
} from "@vidstack/react";
import {
  defaultLayoutIcons,
  DefaultVideoLayout,
} from "@vidstack/react/player/layouts/default";
import { NotificationContext } from "../../providers/NotificationProvider";
import { ConfirmationContext } from "../../providers/ConfirmationProvider";
import "./EditVideo.css";

const api = axios.create({
  baseURL: `${process.env.REACT_APP_API}`,
  withCredentials: true,
});

export default function EditVideo() {
  const initialData = useRef();
  const player = useRef(null);
  const { currentTime } = useStore(MediaPlayerInstance, player);
  const { videourl } = useParams();
  const notify = useContext(NotificationContext);
  const { confirm, closeConfirmation } = useContext(ConfirmationContext);
  const Navigate = useNavigate();
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [thumbnail, setThumbnail] = useState(null);
  const [url, setUrl] = useState("");
  const [video, setVideo] = useState(null);
  const [active, setActive] = useState(false);
  const [episode, setEpisode] = useState("");
  const [metaTitle, setMetaTitle] = useState("");
  const [videoPreview, setVideoPreview] = useState(null);
  const [metaDescription, setMetaDescription] = useState("");
  const [HanimeLink, setHanimeLink] = useState("");
  const handleHanime = () => {
    api
      .put(`/scrape`, { videoUrl: url, url: HanimeLink })
      .then((response) => {
        notify("success", "Category updated successfully");
        if (url !== initialData.current?.url) Navigate(`/videos/edit/${url}`);
      })
      .catch((error) => {
        notify("error", "Video: " + error.response.data.error);
      });
  };

  // For categories
  const [categories, setCategories] = useState([]);
  const [allCategories, setAllCategories] = useState([]);
  const [searchCategories, setSearchCategories] = useState("");
  const filteredCategories = allCategories.filter((category) =>
    category.name.toLowerCase().includes(searchCategories.toLowerCase())
  );

  // For anime
  const [animes, setAnimes] = useState(null);
  const [allAnimes, setAllAnimes] = useState([]);
  const [searchAnimes, setSearchAnimes] = useState([]);
  const [searchTermAnimes, setSearchTermAnimes] = useState("");
  const filteredAnimes = (searchTerm) => {
    setSearchTermAnimes(searchTerm);
    const results = allAnimes.filter((anime) =>
      anime.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setSearchAnimes(results);
  };
  const deleteAnimes = () => {
    setAnimes(null);
    setEpisode("");
    setSearchTermAnimes("");
  };

  // Handle preview
  const [startTime, setStartTime] = useState(0);
  const [endTime, setEndTime] = useState(0);
  const mediaStore = useMediaStore(player);
  const { duration } = mediaStore;

  const handleTimeUpdate = useCallback((time) => {
    console.log("Current Time:", time);
  }, []);

  const handleStartTimeChange = useCallback((e) => {
    const value = parseFloat(e.target.value);
    setStartTime(value);
    if (player.current) {
      player.current.currentTime = value;
    }
  }, []);

  const handleEndTimeChange = useCallback((e) => {
    const value = parseFloat(e.target.value);
    setEndTime(value);
    if (player.current) {
      player.current.currentTime = value;
    }
  }, []);

  // Uploading effects
  const [progress, setProgress] = useState(0);
  const [isUploading, setIsUploading] = useState(false);
  const [progressInscreen, setProgressInscreen] = useState(false);
  const [subtitles, setSubtitles] = useState([]);
  const [subtitlesToRemove, setSubtitlesToRemove] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [isReconverting, setIsReconverting] = useState(false);

  const handleReconvert = () => {
    setIsReconverting(true);
    api
      .post(`/videos/reconvert/${videourl}`)
      .then((response) => {
        notify("success", "Video reconversion started successfully.");
      })
      .catch((error) => {
        notify("error", `Reconversion failed: ${error.response.data.error}`);
      })
      .finally(() => {
        setIsReconverting(false);
      });
  };

  useEffect(() => {
    setIsLoading(true);
    setIsDisabled(true);
    api
      .get(`/videos/${videourl}`)
      .then((response) => {
        console.log(response.data);
        if (response && response.data) {
          const data = response.data;
          console.log(data);
          const newThumbnail =
            process.env.REACT_APP_API +
            data.thumbnail.replace(/uploads[/\\]/, "/static/");
          const newAnimeId = data.anime && data.anime.id ? data.anime.id : null;
          const newEpisode = data.episode || "";
          const newTitle = data.title;
          const newDescription = data.description;
          const newCategories = data.Categories || [];
          const newSubtitles = data.Subtitles || [];
          const newActive = data.active;
          setTitle(newTitle);
          setDescription(newDescription);
          setUrl(data.url);
          setCategories(newCategories);
          setAnimes(data.anime);
          setEpisode(newEpisode);
          setSubtitles(
            newSubtitles.map((sub) => ({
              id: sub.id,
              language: sub.language,
              filePath: null,
            }))
          );
          setActive(newActive);
          setThumbnail(newThumbnail);
          setProgress(parseFloat(data.processProgress.toFixed(2)));
          setMetaDescription(data.metaDescription);
          setMetaTitle(data.metaTitle);
          setVideoPreview(
            process.env.REACT_APP_API +
              data.videoPreview.replace(/uploads[/\\]/, "/static/")
          );
          if (data.processProgress === 100) {
            setVideo(
              `${process.env.REACT_APP_API}/static/videos/${data.url}/video/converted/master.m3u8`
            );
          } else {
            setVideo(
              process.env.REACT_APP_API +
                data.videoPath.replace(/uploads[/\\]/, "/static/")
            );
          }
          console.log(video);

          initialData.current = {
            url: data.url,
            thumbnail: newThumbnail,
            animeId: newAnimeId,
            episode: newEpisode,
            title: newTitle,
            description: newDescription,
            categories: newCategories,
            subtitles: data.Subtitles || [],
            active: newActive,
            metaTitle: data.metaTitle,
            metaDescription: data.metaDescription,
          };
        }
      })
      .catch((error) => {
        notify("error", error.response.data.error);
      })
      .finally(() => {
        setIsLoading(false);
        setIsDisabled(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [videourl, progressInscreen]);

  // Function to handle file change
  const handleFileChange = (index) => (e) => {
    const newSubtitles = [...subtitles];
    newSubtitles[index].filePath = e.target.files[0];
    setSubtitles(newSubtitles);
  };

  // Function to handle language input
  const handleLanguageInput = (index) => (e) => {
    const value = e.target.value.trim();
    const newSubtitles = [...subtitles];
    newSubtitles[index].language = value;
    setSubtitles(newSubtitles);
  };

  // Function to add a new subtitle
  const addSubtitle = () => {
    setSubtitles([...subtitles, { filePath: null, language: "" }]);
  };

  // Function to remove a subtitle
  const removeSubtitle = (index) => {
    const subToRemove = subtitles[index];
    if (subToRemove.id) {
      setSubtitlesToRemove([...subtitlesToRemove, subToRemove.id]);
    }
    setSubtitles(subtitles.filter((_, i) => i !== index));
  };

  const optimizeDescription = () => {
    api
      .get(`/videos/optimize/description/${url}`)
      .then((response) => {
        setDescription(response.data.aiDescription);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const handleUpdate = (e) => {
    e.preventDefault();
    const formData = new FormData();
    console.log("this is the current data");
    console.log(initialData.current);
    if (url && url !== initialData.current?.url) formData.append("url", url);
    if (animes && animes.id !== initialData.current?.animeId)
      formData.append("animeId", animes.id);
    if (episode !== initialData.current?.episode)
      formData.append("episode", episode);
    if (title !== initialData.current?.title) formData.append("title", title);
    if (description !== initialData.current?.description)
      formData.append("description", description);
    if (categories !== initialData.current?.categories)
      formData.append("categories", categories);
    if (active !== initialData.current?.active)
      formData.append("active", active);
    if (metaTitle !== initialData.current?.metaTitle)
      formData.append("metaTitle", metaTitle);
    if (metaDescription !== initialData.current?.metaDescription)
      formData.append("metaDescription", metaDescription);
    if (thumbnail && thumbnail !== initialData.current?.thumbnail)
      formData.append("thumbnail", thumbnail);
    if (subtitles.length > 0) {
      subtitles.forEach((subtitle) => {
        if (subtitle.filePath && subtitle.language) {
          formData.append(`subtitles[${subtitle.language}]`, subtitle.filePath);
        }
      });
    }
    if (startTime > 0.1) {
      formData.append("thumbnailTime", startTime);
    }
    if (endTime > 0.1) {
      formData.append("videoPreviewTime", endTime);
    }
    // Remove subtitles
    if (subtitlesToRemove.length > 0) {
      formData.append("subtitlesToRemove", JSON.stringify(subtitlesToRemove));
    }
    // if formData is empty notify the user
    if ([...formData.keys()].length === 0) {
      notify("error", "No data had been updated");
      return;
    }
    setIsLoading(true);
    setIsDisabled(true);
    api
      .put(`/videos/${videourl}`, formData)
      .then((response) => {
        notify("success", "Video updated successfully");
        if (url !== initialData.current?.url) Navigate(`/videos/edit/${url}`);
      })
      .catch((error) => {
        notify("error", "Video: " + error.response.data.error);
      })
      .finally(() => {
        setIsLoading(false);
        setIsDisabled(false);
      });
  };

  useEffect(() => {
    if (progress !== 100 && progress !== 0) {
      setIsUploading(true);
      const interval = setInterval(() => {
        console.log(progress);
        api
          .get(`/videos/process/${videourl}`)
          .then((response) => {
            setProgress(parseFloat(response.data.processProgress.toFixed(2)));
            if (progress === 100) {
              // Change the state of active
              clearInterval(interval);
              setIsUploading(false);
              setProgressInscreen(true);
            }
          })
          .catch((error) => {
            console.error(error);
          });
      }, 2000);

      return () => clearInterval(interval);
    }
  }, [progress, videourl]);

  // Get all categories
  useEffect(() => {
    api
      .get("/categories/all")
      .then((response) => {
        setAllCategories(response.data);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }, []);

  // Handle the categories
  const handleCheckboxChange = (e) => {
    const categoryId = parseInt(e.target.value, 10); // Convert the value to a number
    if (e.target.checked) {
      // If the checkbox is checked, add the category to the state
      setCategories((prevCategories) => [...prevCategories, categoryId]);
    } else {
      // If the checkbox is unchecked, remove the category from the state
      setCategories((prevCategories) =>
        prevCategories.filter((id) => id !== categoryId)
      );
    }
  };

  // Get all anime
  useEffect(() => {
    api
      .get("/animes/all")
      .then((response) => {
        setAllAnimes(response.data);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }, []);

  const handleDelete = (e) => {
    e.preventDefault();
    setIsLoading(true);
    setIsDisabled(true);

    const token = localStorage.getItem("token"); // Get the token from local storage
    confirm(
      "Are you sure?",
      "When you delete a video, all associated data will also be deleted.",
      () => {
        api
          .delete(`/videos/${videourl}`, {
            headers: {
              Authorization: `Bearer ${token}`, // Add the token to the request headers
            },
          })
          .then((response) => {
            notify("success", "Video deleted successfully");
            Navigate("/videos");
            closeConfirmation();
            // Redirect to another page or update the state to reflect the deletion
          })
          .catch((error) => {
            notify("error", error.response.data.error);
            closeConfirmation();
          })
          .finally(() => {
            setIsLoading(false);
            setIsDisabled(false);
          });
      }
    );
  };

  return (
    <>
      <Header title={`Edit ${title} video`}>
        <div className="switcher">
          <label
            className={`switch ${progress === 100 ? "" : "disabled-toggle"}`}
          >
            <input
              disabled={progress === 100 ? false : true}
              checked={active}
              onClick={() => {
                if (progress === 100) {
                  setActive(!active);
                }
              }}
              type="checkbox"
            />
            <span className="slider round icon"></span>
          </label>
        </div>
        <Button
          loading={isReconverting}
          disable={isDisabled || isReconverting}
          onClick={handleReconvert}
        >
          {isReconverting ? "Reconverting..." : "Reconvert Video"}
        </Button>
        <Button
          loading={isLoading}
          disable={isDisabled}
          type="dangerous"
          icon="delete"
          onClick={handleDelete}
        >
          Delete Video
        </Button>
        <Button loading={isLoading} disable={isDisabled} onClick={handleUpdate}>
          Update Video
        </Button>

        <Button
          loading={isLoading}
          disable={isDisabled}
          type="dark"
          url="/videos"
          icon="back"
        />
      </Header>
      {isUploading && progress !== 100 && progress < 100 && (
        <>
          <div className="progresspopup">
            <div
              className="progressclosebutton"
              onClick={() => {
                setIsUploading(false);
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="feather feather-x"
              >
                <line x1="18" y1="6" x2="6" y2="18"></line>
                <line x1="6" y1="6" x2="18" y2="18"></line>
              </svg>
            </div>

            <div className="progressIcon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="feather feather-cpu"
              >
                <rect x="4" y="4" width="16" height="16" rx="2" ry="2"></rect>
                <rect x="9" y="9" width="6" height="6"></rect>
                <line x1="9" y1="1" x2="9" y2="4"></line>
                <line x1="15" y1="1" x2="15" y2="4"></line>
                <line x1="9" y1="20" x2="9" y2="23"></line>
                <line x1="15" y1="20" x2="15" y2="23"></line>
                <line x1="20" y1="9" x2="23" y2="9"></line>
                <line x1="20" y1="14" x2="23" y2="14"></line>
                <line x1="1" y1="9" x2="4" y2="9"></line>
                <line x1="1" y1="14" x2="4" y2="14"></line>
              </svg>
            </div>
            <div className="progressContent">
              <h1>Just a minute...</h1>
              <p>
                Your video is being processed and converted to other qualities.
                You should wait until it's done. However, you can edit the
                majority of the video data.
              </p>
              <div className="progressbarbutton">
                <div>
                  <p>{`${progress}%`}</p>
                  <progress value={progress} max="100" />
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      <div className="mainlayout_content">
        <div className="demo-page video">
          <div className="demo-page-navigation video">
            <nav>
              <MediaPlayer
                ref={player}
                title={title}
                src={video ? video : ""}
                onTimeUpdate={(e) => handleTimeUpdate(e.currentTime)}
              >
                <MediaProvider>
                  <Poster
                    key={thumbnail}
                    className="vds-poster"
                    src={
                      thumbnail instanceof Blob
                        ? URL.createObjectURL(thumbnail)
                        : thumbnail
                        ? thumbnail
                        : "https://media-files.vidstack.io/sprite-fight/poster.webp"
                    }
                  />
                </MediaProvider>
                <DefaultVideoLayout
                  thumbnails="https://image.mux.com/VZtzUzGRv02OhRnZCxcNg49OilvolTqdnFLEqBsTwaxU/storyboard.vtt"
                  icons={defaultLayoutIcons}
                />
              </MediaPlayer>
              <div>
                <div className="preview-title">
                  <h1>{title ? title : "Write something in the title"}</h1>
                  <span>{episode ? `Episode ${episode}` : "Add Anime"}</span>
                </div>
                <div className="preview-account"></div>
                <div className="preview-description">
                  <h2>Description</h2>
                  <p>
                    {description
                      ? description
                      : "You should write something in the description"}
                  </p>
                  <div className="Categories">
                    <h3>Categories</h3>
                    <div>
                      {categories.length > 0 ? (
                        categories.map((categoryId) => {
                          const category = filteredCategories.find(
                            (category) => category.id === categoryId
                          );
                          return category ? (
                            <p key={categoryId}>{category.name}</p>
                          ) : null;
                        })
                      ) : (
                        <p>Add a category</p>
                      )}
                    </div>
                  </div>
                </div>

                <div className="preview-subtitles">
                  <h2>Subtitles</h2>
                  {subtitles.length > 0 ? (
                    subtitles.map((subtitle, index) =>
                      subtitle.language !== "" ? (
                        <p key={index}>{subtitle.language}</p>
                      ) : (
                        <p key={index}>Choose language</p>
                      )
                    )
                  ) : (
                    <p>Add a subtitle</p>
                  )}
                </div>
              </div>
              <div className="optimizationButtons">
                <Button onClick={optimizeDescription}>
                  Optimize Description
                </Button>
              </div>
            </nav>
          </div>
          <main className="demo-page-content">
            <section>
              <div className="href-target" id="Main"></div>
              <h1>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="feather feather-layers"
                >
                  <polygon points="12 2 2 7 12 12 22 7 12 2" />
                  <polyline points="2 17 12 22 22 17" />
                  <polyline points="2 12 12 17 22 12" />
                </svg>
                Main
              </h1>
              <p>From here you could add a video</p>

              <div className="nice-form-group avatar">
                <label>Thumbnail</label>
                <input
                  type="file"
                  name="thumbnail"
                  onChange={(e) => setThumbnail(e.target.files[0])}
                  required
                />
              </div>
              <div className="nice-form-group">
                <label>Title</label>
                <input
                  onChange={(e) => setTitle(e.target.value)}
                  value={title}
                  type="text"
                  placeholder="video title"
                  required
                />
              </div>

              <div className="nice-form-group">
                <label>Description</label>
                <textarea
                  rows="5"
                  placeholder="Your description"
                  onChange={(e) => setDescription(e.target.value)}
                  value={description}
                ></textarea>
              </div>
              <div className="nice-form-group">
                <label>Url</label>
                <input
                  type="text"
                  onChange={(e) => setUrl(e.target.value)}
                  name="url"
                  value={url}
                  placeholder="video url"
                  required
                />
              </div>
            </section>
            <section className="removeoverflow">
              <div className="href-target" id="Main"></div>
              <div className="subtitle-header">
                <h1>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="feather feather-layers"
                  >
                    <polygon points="12 2 2 7 12 12 22 7 12 2" />
                    <polyline points="2 17 12 22 22 17" />
                    <polyline points="2 12 12 17 22 12" />
                  </svg>
                  Preview Controler
                </h1>
              </div>
              <p>Handle the videoPreview and thumbnail</p>
              <div className="preview-controller">
                <div className="slider-container">
                  <label>Time: {startTime.toFixed(2)}s</label>
                  <input
                    type="range"
                    min="0"
                    max={duration}
                    step="0.01"
                    value={startTime}
                    onChange={handleStartTimeChange}
                  />
                </div>
                <div style={{ width: "250px" }}>
                  <img
                    style={{ width: "250px" }}
                    src={
                      thumbnail instanceof Blob
                        ? URL.createObjectURL(thumbnail)
                        : thumbnail
                    }
                  />
                </div>
                <div className="slider-container">
                  <label>Time: {endTime.toFixed(2)}s</label>
                  <input
                    type="range"
                    min="0"
                    max={duration}
                    step="0.01"
                    value={endTime}
                    onChange={handleEndTimeChange}
                  />
                </div>
                <div style={{ width: "250px" }}>
                  <video
                    width="100%"
                    controls
                    preload="none"
                    src={videoPreview ? videoPreview : ""}
                  ></video>
                </div>
              </div>
            </section>

            <section className="removeoverflow">
              <div className="href-target" id="Main"></div>
              <div className="subtitle-header">
                <h1>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="feather feather-layers"
                  >
                    <polygon points="12 2 2 7 12 12 22 7 12 2" />
                    <polyline points="2 17 12 22 22 17" />
                    <polyline points="2 12 12 17 22 12" />
                  </svg>
                  Anime
                </h1>
                {animes && (
                  <Button type="dangerous" icon="delete" onClick={deleteAnimes}>
                    Delete Anime
                  </Button>
                )}
              </div>

              <p>Handle the Anime that this video belong</p>
              <div className="search-anime-layout">
                {animes && animes.image && (
                  <div>
                    <img
                      src={
                        process.env.REACT_APP_API +
                        animes.image.replace(/uploads[/\\]/, "/static/")
                      }
                      alt=""
                    />
                  </div>
                )}
                <div>
                  <div className="nice-form-group">
                    <label>Search</label>
                    <input
                      type="search"
                      placeholder="Search Anime"
                      value={
                        animes
                          ? ` ${animes.id} // ${animes.name}`
                          : searchTermAnimes
                      }
                      onChange={(e) => filteredAnimes(e.target.value)}
                    />
                  </div>
                  {searchTermAnimes !== "" && !animes && (
                    <div className="dropdown">
                      <div className="dropdown-content">
                        <div>
                          {searchAnimes.map((anime) => (
                            <div
                              key={anime.id}
                              onClick={() => {
                                setAnimes(anime);
                                setEpisode(anime.videos.length + 1);
                                /// can i set teh value of the input from here
                              }}
                            >
                              {anime.name}
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="nice-form-group">
                    <label>Episode</label>
                    <input
                      onChange={(e) => setEpisode(e.target.value)}
                      value={episode}
                      type="text"
                      placeholder="You should first choose an anime"
                    />
                  </div>
                </div>
              </div>
            </section>
            <section className="removeoverflow">
              <div className="href-target" id="SubTitle"></div>
              <div className="subtitle-header">
                <h1>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="feather feather-layers"
                  >
                    <polygon points="12 2 2 7 12 12 22 7 12 2" />
                    <polyline points="2 17 12 22 22 17" />
                    <polyline points="2 12 12 17 22 12" />
                  </svg>
                  Subtitles
                </h1>

                <Button
                  disable={false} // Allow adding subtitles with any language
                  type="default"
                  onClick={addSubtitle}
                >
                  Add Subtitle
                </Button>
              </div>

              <p>Handle the Subtitles of this video</p>
              <div className="subtitle-container">
                {subtitles.map((subtitle, index) => (
                  <div className="inner-subtitle-container" key={index}>
                    <div className="nice-form-group avatar">
                      <label>Language: </label>
                      <input
                        type="text"
                        name={`language-${index}`}
                        placeholder="e.g., en-us"
                        value={subtitle.language}
                        onChange={handleLanguageInput(index)}
                        required
                      />
                      <label>Subtitle File: </label>
                      <input
                        type="file"
                        accept=".vtt,.srt"
                        name={`subtitle-${index}`}
                        onChange={handleFileChange(index)}
                        required
                      />
                    </div>
                    <Button
                      type="dangerous"
                      onClick={() => removeSubtitle(index)}
                    >
                      Remove
                    </Button>
                  </div>
                ))}
              </div>
            </section>
            <section>
              <div className="href-target" id="Main"></div>
              <div className="subtitle-header">
                <h1>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="feather feather-layers"
                  >
                    <polygon points="12 2 2 7 12 12 22 7 12 2" />
                    <polyline points="2 17 12 22 22 17" />
                    <polyline points="2 12 12 17 22 12" />
                  </svg>
                  Categories
                </h1>
              </div>

              <p>Add Categories</p>
              <div className="nice-form-group">
                <label>using a Hanime</label>
                <input
                  value={HanimeLink}
                  onChange={(e) => setHanimeLink(e.target.value)}
                  type="text"
                  placeholder="Meta title"
                />
              </div>
              <Button onClick={handleHanime}>Get Categories</Button>
              <div className="nice-form-group">
                <input
                  type="search"
                  placeholder="Search Categories"
                  onChange={(e) => setSearchCategories(e.target.value)}
                />
              </div>
              <div className="categories-container">
                {filteredCategories.map((category) => (
                  <div className="inner_categories_container">
                    <div className="nice-form-group" key={category.id}>
                      <input
                        type="checkbox"
                        id={`check-${category.id}`}
                        value={category.id}
                        onChange={handleCheckboxChange}
                        checked={categories.includes(category.id)} // Use categories here
                      />
                      <label htmlFor={`check-${category.id}`}>
                        {category.name}
                      </label>
                    </div>
                  </div>
                ))}
              </div>
            </section>
            <section>
              <div className="href-target" id="seo"></div>
              <h1>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="feather feather-layers"
                >
                  <polygon points="12 2 2 7 12 12 22 7 12 2" />
                  <polyline points="2 17 12 22 22 17" />
                  <polyline points="2 12 12 17 22 12" />
                </svg>
                SEO
              </h1>
              <p>Adding meta tags to main page</p>
              <div className="nice-form-group">
                <label>Title</label>
                <input
                  value={metaTitle}
                  onChange={(e) => setMetaTitle(e.target.value)}
                  type="text"
                  placeholder="Meta title"
                />
              </div>
              <div className="nice-form-group">
                <label>Description</label>
                <textarea
                  rows="5"
                  onChange={(e) => setMetaDescription(e.target.value)}
                  placeholder="meta desciption"
                  value={metaDescription}
                ></textarea>
              </div>
            </section>
          </main>
        </div>
      </div>
    </>
  );
}
