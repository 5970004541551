import React, { useState, useEffect, useMemo } from "react";
import Header from "../../components/Header";
import MyButton from "../../components/Button";
import axios from "axios";
//MRT Imports
import {
  MaterialReactTable,
  useMaterialReactTable,
  MRT_GlobalFilterTextField as MrtGlobalFilterTextField,
  MRT_ToggleFiltersButton as MrtToggleFiltersButton,
} from "material-react-table";

//Material UI Imports
import { Box, Button, ListItemIcon, MenuItem } from "@mui/material";

//Icons Imports
import { AccountCircle } from "@mui/icons-material";
//Date Picker Imports - these should just be in your Context Provider
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import ReadMore from "../../components/ReadMore";
const api = axios.create({
  baseURL: `${process.env.REACT_APP_API}`,
  withCredentials: true,
});
const darkTheme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#ff5722", // custom primary color
    },
  },
});
const Example = () => {
  const [data, setData] = useState([]);
  const columns = useMemo(
    () => [
      {
        accessorKey: "id",
        header: "ID",
        size: 10,
      },
      {
        accessorFn: (row) => `${row.name}`, //accessorFn used to join multiple data into a single cell
        id: "name", //id is still required when using accessorFn instead of accessorKey
        header: "Name",
        size: 250,
        Cell: ({ renderedCellValue, row }) => (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "1.5rem",
            }}
          >
            <img
              alt="anime"
              style={{
                height: "150px",
                width: "auto",
                aspectRatio: "17 / 25",
                borderRadius: "10px",
                objectFit: "cover",
              }}
              src={process.env.REACT_APP_API + row.original.image}
              loading="lazy"
            />
            {/* using renderedCellValue instead of cell.getValue() preserves filter match highlighting */}
            <span style={{ fontSize: "20px", fontWeight: 600 }}>
              {renderedCellValue}
            </span>
          </Box>
        ),
      },
      {
        accessorKey: "description",
        header: "Description",
        size: 250,
        Cell: ({ cell: { renderedCellValue, row } }) => (
          <ReadMore>{row.original.description}</ReadMore>
        ),
      },
      {
        accessorFn: (row) => new Date(row.createdAt),
        id: "createdAt",
        filterVariant: "date",
        header: "Created At",
        filterFn: "lessThan",
        sortingFn: "datetime",
        Cell: ({ cell }) => cell.getValue()?.toLocaleDateString(), //render Date as a string
        Header: ({ column }) => <em>{column.columnDef.header}</em>,
        muiFilterTextFieldProps: {
          sx: {
            minWidth: "100px",
          },
        },
      },
      {
        accessorFn: (row) => new Date(row.createdAt),
        id: "updatedAt",
        filterVariant: "date",
        header: "Updated At",
        filterFn: "lessThan",
        sortingFn: "datetime",
        Cell: ({ cell }) => cell.getValue()?.toLocaleDateString(), //render Date as a string
        Header: ({ column }) => <em>{column.columnDef.header}</em>,
        muiFilterTextFieldProps: {
          sx: {
            minWidth: "100px",
          },
        },
      },
    ],
    []
  );
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10, //customize the default page size
  });
  const Navigate = useNavigate();

  const table = useMaterialReactTable({
    columns,
    data, //data must be memoized or stable (useState, useMemo, defined outside of this component, etc.)
    enableColumnFilterModes: true,
    enableColumnOrdering: true,
    enableColumnPinning: true,
    enableFacetedValues: true,
    enableRowSelection: true,
    enableRowActions: true,
    initialState: { showColumnFilters: true, showGlobalFilter: true },
    paginationDisplayMode: "pages",
    positionToolbarAlertBanner: "bottom",
    muiSearchTextFieldProps: {
      size: "small",
      variant: "outlined",
    },
    onPaginationChange: setPagination, //hoist pagination state to your state when it changes internally
    state: { pagination }, //pass the pagination state to the table
    muiTablePaperProps: {
      elevation: 2, //change the mui box shadow
      //customize paper styles
      sx: {
        p: "1rem 1.5rem",
        borderRadius: "1.5rem",
      },
    },
    mrtTheme: (theme) => ({
      baseBackgroundColor: "#080710",
      draggingBorderColor: theme.palette.secondary.main,
    }),
    muiPaginationProps: {
      color: "secondary",
      rowsPerPageOptions: [10, 20, 30],
      shape: "rounded",
      variant: "outlined",
    },
    renderRowActionMenuItems: ({ row }, menuProps) => [
      <MenuItem
        key={0}
        onClick={() => {
          Navigate(`edit/${row.original.url}`);
          if (menuProps && menuProps.closeMenu) {
            menuProps.closeMenu();
          }
        }}
        sx={{ m: 0 }}
      >
        <ListItemIcon>
          <AccountCircle />
        </ListItemIcon>
        Edit Profile
      </MenuItem>,
    ],
    renderTopToolbar: ({ table }) => {
      const handleDeactivate = () => {
        table.getSelectedRowModel().flatRows.forEach((row) => {
          alert("deactivating " + row.getValue("name"));
        });
      };

      const handleActivate = () => {
        table.getSelectedRowModel().flatRows.forEach((row) => {
          alert("activating " + row.getValue("name"));
        });
      };

      const handleContact = () => {
        table.getSelectedRowModel().flatRows.forEach((row) => {
          alert("contact " + row.getValue("name"));
        });
      };

      return (
        <Box
          sx={(theme) => ({
            backgroundColor: "#080710",
            display: "flex",
            gap: "0.5rem",
            p: "1rem",
            justifyContent: "space-between",
          })}
        >
          <Box sx={{ display: "flex", gap: "0.5rem", alignItems: "center" }}>
            {/* import MRT sub-components */}
            <MrtGlobalFilterTextField table={table} />
            <MrtToggleFiltersButton table={table} />
          </Box>
          <Box>
            <Box sx={{ display: "flex", gap: "0.5rem" }}>
              <Button
                color="error"
                disabled={!table.getIsSomeRowsSelected()}
                onClick={handleDeactivate}
                variant="contained"
              >
                Deactivate
              </Button>
              <Button
                color="success"
                disabled={!table.getIsSomeRowsSelected()}
                onClick={handleActivate}
                variant="contained"
              >
                Activate
              </Button>
              <Button
                color="info"
                disabled={!table.getIsSomeRowsSelected()}
                onClick={handleContact}
                variant="contained"
              >
                Contact
              </Button>
            </Box>
          </Box>
        </Box>
      );
    },
  });

  useEffect(() => {
    api
      .get(`/animes/all`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        console.log(response.data);
        const newData = response.data.map((item) => {
          if (item.image) {
            item.image = item.image.replace(/uploads[/\\]/g, "/static/");
          }
          return item;
        });
        setData(newData);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  return <MaterialReactTable table={table} />;
};

export default function MainAnimes() {
  return (
    <>
      <Header title="Animes">
        <MyButton icon="plus" url="add" type="default">
          Add Anime
        </MyButton>
      </Header>
      <div className="mainlayout_content">
        <div className="mainlayout">
          <ThemeProvider theme={darkTheme}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Example />
            </LocalizationProvider>
          </ThemeProvider>
        </div>
      </div>
    </>
  );
}
