import { createContext, useState } from "react";
import "./ConfirmationProvider.css";
import Button from "../components/Button";

export const ConfirmationContext = createContext();

const ConfirmationPopup = ({ title, message, onConfirm, onClose }) => (
  <>
    <div className="overlay open"></div>
    <div className="confirmation-popup">
      <h2>{title}</h2>
      <p>{message}</p>
      <div>
        <Button type="dangerous" onClick={onConfirm}>
          Yes
        </Button>
        <Button type="dark" onClick={onClose}>
          No
        </Button>
      </div>
    </div>
  </>
);

export const ConfirmationProvider = ({ children }) => {
  const [confirmation, setConfirmation] = useState(null);

  const confirm = (title, message, onConfirm) => {
    setConfirmation({ title, message, onConfirm });
  };

  const closeConfirmation = () => {
    setConfirmation(null);
  };

  return (
    <ConfirmationContext.Provider value={{ confirm, closeConfirmation }}>
      {children}
      {confirmation && (
        <ConfirmationPopup
          title={confirmation.title}
          message={confirmation.message}
          onConfirm={confirmation.onConfirm}
          onClose={closeConfirmation}
        />
      )}
    </ConfirmationContext.Provider>
  );
};
