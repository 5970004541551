import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ConfirmationProvider } from "./providers/ConfirmationProvider";

import MainLayout from "./layouts/MainLayout";
import MainPage from "./pages/MainPage";
import MainAccounts from "./pages/accounts/MainAccounts";
import AddAccount from "./pages/accounts/AddAccount";
import EditAccount from "./pages/accounts/EditAccount";
import MainVideos from "./pages/videos/MainVideos";
import EditVideo from "./pages/videos/EditVideo";
import AddVideo from "./pages/videos/AddVideo";

import MainCategories from "./pages/categories/MainCategories";
import EditCategory from "./pages/categories/EditCategory";
import AddCategory from "./pages/categories/AddCategory";

import MainAnimes from "./pages/animes/MainAnimes";
import EditAnime from "./pages/animes/EditAnime";
import AddAnime from "./pages/animes/AddAnime";

import MainLog from "./pages/log/MainLog";

import Login from "./pages/login/Login";
import AuthenticatedApp from "./providers/AuthenticatedApp";

function App() {
  return (
    <Router>
      <ConfirmationProvider>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/*" element={<AuthenticatedApp />}>
            <Route element={<MainLayout />}>
              {/* main page */}
              <Route index element={<MainPage />} />
              {/* accounts */}
              <Route path="accounts" element={<MainAccounts />} />
              <Route path="accounts/add" element={<AddAccount />} />
              <Route path="accounts/edit/:url" element={<EditAccount />} />
              {/* videos */}
              <Route path="videos" element={<MainVideos />} />
              <Route path="videos/add" element={<AddVideo />} />
              <Route path="videos/edit/:videourl" element={<EditVideo />} />
              {/* categories */}
              <Route path="categories" element={<MainCategories />} />
              <Route path="categories/add" element={<AddCategory />} />
              <Route path="categories/edit/:url" element={<EditCategory />} />
              {/* animes */}
              <Route path="animes" element={<MainAnimes />} />
              <Route path="animes/add" element={<AddAnime />} />
              <Route path="animes/edit/:url" element={<EditAnime />} />
              {/* log in the websites */}
              <Route path="log" element={<MainLog/>} />
            </Route>
          </Route>
        </Routes>
      </ConfirmationProvider>
    </Router>
  );
}

export default App;
