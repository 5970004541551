import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import Header from "../../components/Header";
import Button from "../../components/Button";

import "./AddVideo.css";
import "@vidstack/react/player/styles/default/theme.css";
import "@vidstack/react/player/styles/default/layouts/video.css";
import {
  MediaPlayer,
  MediaProvider,
  Poster,
  MediaPlayerInstance,
  useStore,
} from "@vidstack/react";
import {
  defaultLayoutIcons,
  DefaultVideoLayout,
} from "@vidstack/react/player/layouts/default";
import { NotificationContext } from "../../providers/NotificationProvider";
import { useNavigate } from "react-router-dom";
import { useRef } from "react";

const api = axios.create({
  baseURL: `${process.env.REACT_APP_API}`,
  withCredentials: true,
});

export default function AddVideo() {
  const player = useRef(null);
  const { currentTime } = useStore(MediaPlayerInstance, player);
  const notify = useContext(NotificationContext);
  const navigate = useNavigate();
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [thumbnail, setThumbnail] = useState(null);
  const [url, setUrl] = useState("");
  const [video, setVideo] = useState(null);
  const [episode, setEpisode] = useState("");
  const [active, setActive] = useState(true);
  const [metaTitle, setMetaTitle] = useState("");
  const [metaDescription, setMetaDescription] = useState("");
  // for categories
  const [categories, setCategories] = useState([]);
  const [allCategories, setAllCategories] = useState([]);
  const [searchCategories, setSearchCategories] = useState("");
  const filteredCategories = allCategories.filter((category) =>
    category.name.toLowerCase().includes(searchCategories.toLowerCase())
  );
  // for anime
  const [animes, setAnimes] = useState(null);
  const [allAnimes, setAllAnimes] = useState([]);
  const [searchAnimes, setSearchAnimes] = useState([]);
  const [searchTermAnimes, setSearchTermAnimes] = useState("");
  const filteredAnimes = (searchTerm) => {
    setSearchTermAnimes(searchTerm);
    const results = allAnimes.filter((anime) =>
      anime.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setSearchAnimes(results);
  };
  const deleteAnimes = () => {
    setAnimes(null);
    setEpisode("");
    setSearchTermAnimes("");
  };
  // uploading effects
  const [uploadPercentage, setUploadPercentage] = useState(0);
  const [isUploading, setIsUploading] = useState(false);

  const [subtitles, setSubtitles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  // hundling the AI description
  const [AIGenerateDescription, setAIGenerateDescription] = useState(true);
  const handleAIGenerateDescription = () => {
    setAIGenerateDescription(!AIGenerateDescription);
  };
  // Function to handle file change
  const handleFileChange = (index) => (e) => {
    const newSubtitles = [...subtitles];
    newSubtitles[index].filePath = e.target.files[0];
    setSubtitles(newSubtitles);
  };

  // Define the available languages
  const languages = [
    { value: "en-us", label: "English" },
    { value: "ar-ae", label: "Arabic" },
    // Add more languages as needed
  ];

  // In your component's state, keep track of the selected languages
  const [selectedLanguages, setSelectedLanguages] = useState([]);
  const [noLanguagesLeft, setNoLanguagesLeft] = useState(false);
  // In your handleLanguageChange function, update the selected languages
  const handleLanguageChange = (index) => (event) => {
    const newSelectedLanguages = [...selectedLanguages];
    if (event.target.value === "") {
      newSelectedLanguages[index] = null;
    } else {
      newSelectedLanguages[index] = event.target.value;
    }
    setSelectedLanguages(newSelectedLanguages);

    // Update the language property on the subtitles object
    const newSubtitles = [...subtitles];
    newSubtitles[index].language = event.target.value;
    setSubtitles(newSubtitles);

    // Check if there are no languages left
    const remainingLanguages = languages.filter(
      (language) =>
        !newSelectedLanguages.includes(language.value) || language.value === ""
    );
    if (remainingLanguages.length === 0) {
      setNoLanguagesLeft(true);
    } else {
      setNoLanguagesLeft(false);
    }
  };
  // Function to add a new subtitle
  const addSubtitle = () => {
    setSubtitles([...subtitles, { filePath: null, language: "" }]);
  };
  // Function to add a new subtitle
  const handleCancleUpload = () => {};
  const removeSubtitle = (index) => {
    setSubtitles(subtitles.filter((_, i) => i !== index));
  };

  // creating the url from the title
  useEffect(() => {
    const urlFromTitle = title
      .trim() // Remove leading and trailing spaces
      .toLowerCase()
      .replace(/\s+/g, "-") // Replace one or more spaces with a single hyphen
      .replace(/[^\w-]+/g, "-") // Replace non-alphanumeric and non-hyphen characters with a hyphen
      .replace(/--+/g, "-") // Replace two or more consecutive hyphens with a single hyphen
      .replace(/-+$/, ""); // Remove trailing hyphens
    setUrl(urlFromTitle);
  }, [title]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    setIsDisabled(true);
    const formData = new FormData();

    const validateAndAppendField = (
      fieldName,
      fieldValue,
      maxLength,
      errorMessage
    ) => {
      if (fieldValue) {
        if (fieldValue.length > maxLength) {
          notify("error", errorMessage);
          setIsLoading(false);
          setIsDisabled(false);
          return false;
        }
        formData.append(fieldName, fieldValue);
        return true;
      }
      return true;
    };

    const isUrlValid = () => {
      if (url) {
        if (url.includes(" ")) {
          notify("error", "URL should not contain spaces");
          setIsLoading(false);
          setIsDisabled(false);
          return false;
        }
        if (/[A-Z]/.test(url)) {
          notify("error", "URL should only contain lowercase letters");
          setIsLoading(false);
          setIsDisabled(false);
          return false;
        }
        if (
          !validateAndAppendField(
            "url",
            url,
            255,
            "URL should not be longer than 255 characters"
          )
        ) {
          return false;
        }
      } else {
        notify("error", "URL is required");
        setIsLoading(false);
        setIsDisabled(false);
        return false;
      }
      return true;
    };

    if (!isUrlValid()) {
      return;
    }

    if (video) {
      formData.append("videoPath", video);
    } else {
      notify("error", "Video is required");
      setIsLoading(false);
      setIsDisabled(false);
      return;
    }

    validateAndAppendField(
      "metaTitle",
      metaTitle,
      60,
      "Meta title should not be longer than 60 characters"
    );
    validateAndAppendField(
      "metaDescription",
      metaDescription,
      160,
      "Meta description should not be longer than 160 characters"
    );

    if (animes) {
      formData.append("animeId", animes.id);
      setEpisode(animes.videos.length + 1);
      formData.append("episode", episode);
    }

    if (
      !validateAndAppendField(
        "title",
        title,
        255,
        "Title should not be longer than 255 characters"
      )
    ) {
      return;
    }

    if (description) {
      formData.append("description", description);
    }

    if (categories !== "") {
      formData.append("categories", categories);
    }

    if (active) {
      formData.append("active", active);
    }

    if (subtitles) {
      subtitles.forEach((subtitle, index) => {
        formData.append(`subtitles[${subtitle.language}]`, subtitle.filePath);
      });
    }

    if (thumbnail) {
      formData.append("thumbnail", thumbnail);
    }
    if (currentTime > 0.1) {
      formData.append("thumbnailTime", Math.floor(currentTime));
    }
    if (AIGenerateDescription) {
      formData.append("AIGenerateDescription", AIGenerateDescription);
    }

    setIsUploading(true);
    api
      .post("/videos/add", formData, {
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          setUploadPercentage(percentCompleted);
        },
      })
      .then(() => {
        setIsLoading(false);
        setIsDisabled(false);
        notify("success", "Video added successfully");
        navigate("/videos");
      })
      .catch((error) => {
        setIsLoading(false);
        setIsDisabled(false);
        notify("error", "Video: " + error.response.data.error);
      });
  };
  // get all categories
  useEffect(() => {
    api
      .get("/categories/all")
      .then((response) => {
        setAllCategories(response.data);
        console.log(response.data);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }, []);
  // handle the categories
  const handleCheckboxChange = (e) => {
    const categoryId = parseInt(e.target.value, 10); // Convert the value to a number
    if (e.target.checked) {
      // If the checkbox is checked, add the category to the state
      setCategories((prevCategories) => [...prevCategories, categoryId]);
    } else {
      // If the checkbox is unchecked, remove the category from the state
      setCategories((prevCategories) =>
        prevCategories.filter((id) => id !== categoryId)
      );
    }
  };
  // get all anime
  useEffect(() => {
    api
      .get("/animes/all")
      .then((response) => {
        setAllAnimes(response.data);
        console.log(response.data);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }, []);
  const handleVideoChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setVideo(e.target.files[0]);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      if (video) {
        console.log(URL.createObjectURL(video));
      }
    }, 2000); // 2000 milliseconds = 2 seconds
  }, [video]);
  return (
    <>
      <Header title="Add New Video">
        <div className="switcher">
          <label className="switch">
            <input
              checked={active}
              onChange={() => {
                setActive(!active);
              }}
              type="checkbox"
            />
            <span className="slider round icon"></span>
          </label>
        </div>
        <Button loading={isLoading} disable={isDisabled} onClick={handleSubmit}>
          Add video
        </Button>
        <Button
          loading={isLoading}
          disable={isDisabled}
          type="dark"
          url="/videos"
          icon="back"
        />
      </Header>
      {/* the progress bar status */}
      {isUploading && (
        <>
          <div className="progresspopup">
            <div
              className="progressclosebutton"
              onClick={() => {
                setIsUploading(false);
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="feather feather-x"
              >
                <line x1="18" y1="6" x2="6" y2="18"></line>
                <line x1="6" y1="6" x2="18" y2="18"></line>
              </svg>
            </div>

            <div className="progressIcon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="feather feather-upload-cloud"
              >
                <polyline points="16 16 12 12 8 16"></polyline>
                <line x1="12" y1="12" x2="12" y2="21"></line>
                <path d="M20.39 18.39A5 5 0 0 0 18 9h-1.26A8 8 0 1 0 3 16.3"></path>
                <polyline points="16 16 12 12 8 16"></polyline>
              </svg>
            </div>
            <div className="progressContent">
              <h1>Just a minute...</h1>
              <p>
                Your file is uploading right now. Just give us a second to
                finsih your upload.
              </p>
              <div className="progressbarbutton">
                <div>
                  <p>{`${uploadPercentage}%`}</p>
                  <progress value={uploadPercentage} max="100" />
                </div>
                <Button type="dangerous" onClick={handleCancleUpload}>
                  Cancel
                </Button>
              </div>
            </div>
          </div>
        </>
      )}
      {/* the progress bar status */}
      <div className="mainlayout_content">
        <div className="demo-page video">
          <div className="demo-page-navigation video">
            <nav>
              <MediaPlayer
                ref={player}
                title={title}
                src={
                  video
                    ? video
                    : "https://stream.mux.com/VZtzUzGRv02OhRnZCxcNg49OilvolTqdnFLEqBsTwaxU/low.mp4"
                }
              >
                <MediaProvider>
                  <Poster
                    key={thumbnail}
                    className="vds-poster"
                    src={
                      thumbnail
                        ? URL.createObjectURL(thumbnail)
                        : `${process.env.REACT_APP_API}/thumbnail/default.webp`
                    }
                  />
                </MediaProvider>
                <DefaultVideoLayout
                  thumbnails="https://image.mux.com/VZtzUzGRv02OhRnZCxcNg49OilvolTqdnFLEqBsTwaxU/storyboard.vtt"
                  icons={defaultLayoutIcons}
                />
              </MediaPlayer>

              <div>
                <div className="preview-title">
                  <h1>{title ? title : "Write smth in the title"}</h1>
                  <span>{episode ? `Episode ${episode}` : "Add Anime"}</span>
                </div>
                <div className="preview-account"></div>
                <div className="preview-description">
                  <h2>description</h2>
                  <p>
                    {description
                      ? description
                      : "You should write smth in the description"}
                  </p>
                  <div className="Categories">
                    <h3>Categories</h3>
                    <div>
                      {categories.length > 0 ? (
                        categories.map((categoryId) => {
                          const category = filteredCategories.find(
                            (category) => category.id === categoryId
                          );
                          return category ? (
                            <p key={categoryId}>{category.name}</p>
                          ) : null;
                        })
                      ) : (
                        <p>Add a category</p>
                      )}
                    </div>
                  </div>
                </div>

                <div className="preview-subtitles">
                  <h2>Subtitles</h2>
                  {subtitles.length > 0 ? (
                    subtitles.map((subtitle, index) =>
                      subtitle.language !== "" ? (
                        <p key={index}>{subtitle.language}</p>
                      ) : (
                        <p key={index}>Choose language</p>
                      )
                    )
                  ) : (
                    <p>Add a subtitle</p>
                  )}
                </div>
              </div>
              <div className="inner_categories_container">
                <div className="nice-form-group">
                  <input
                    type="checkbox"
                    checked={AIGenerateDescription}
                    onClick={handleAIGenerateDescription}
                  />
                  <label>Generate description</label>
                </div>
              </div>
            </nav>
          </div>
          <main className="demo-page-content">
            <section>
              <div className="href-target" id="Main"></div>
              <h1>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="feather feather-layers"
                >
                  <polygon points="12 2 2 7 12 12 22 7 12 2" />
                  <polyline points="2 17 12 22 22 17" />
                  <polyline points="2 12 12 17 22 12" />
                </svg>
                Main
              </h1>
              <p>From here you could add a video</p>
              <div className="nice-form-group avatar">
                <label>Video</label>
                <input
                  type="file"
                  name="videoPath"
                  accept="video/*"
                  onChange={handleVideoChange}
                  required
                />
              </div>
              <div className="nice-form-group avatar">
                <label>Thumbnail</label>
                <input
                  type="file"
                  name="thumbnail"
                  accept="image/*"
                  onChange={(e) => setThumbnail(e.target.files[0])}
                />
              </div>
              <div className="nice-form-group">
                <label>Title</label>
                <input
                  onChange={(e) => setTitle(e.target.value)}
                  type="text"
                  placeholder="video title"
                  required
                />
              </div>

              <div className="nice-form-group">
                <label>Description</label>
                <textarea
                  rows="5"
                  placeholder="Your description"
                  onChange={(e) => setDescription(e.target.value)}
                  value={description}
                ></textarea>
              </div>
              <div className="nice-form-group">
                <label>Url</label>
                <input
                  type="text"
                  onChange={(e) => setUrl(e.target.value)}
                  name="url"
                  value={url}
                  placeholder="video url"
                  required
                />
              </div>
            </section>
            <section className="removeoverflow">
              <div className="href-target" id="Main"></div>
              <div className="subtitle-header">
                <h1>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="feather feather-layers"
                  >
                    <polygon points="12 2 2 7 12 12 22 7 12 2" />
                    <polyline points="2 17 12 22 22 17" />
                    <polyline points="2 12 12 17 22 12" />
                  </svg>
                  Anime
                </h1>
                {animes && (
                  <Button type="dangerous" icon="delete" onClick={deleteAnimes}>
                    Delete Anime
                  </Button>
                )}
              </div>

              <p>Handle the Anime that this video belong</p>
              <div className="search-anime-layout">
                <div>
                  <img
                    src={
                      animes && animes.image
                        ? process.env.REACT_APP_API +
                          animes.image.replace(/uploads[/\\]/, "/static/")
                        : process.env.REACT_APP_API +
                          "/static/animes/default.webp"
                    }
                    alt=""
                  />
                </div>
                <div>
                  <div className="nice-form-group">
                    <label>Search</label>
                    <input
                      type="search"
                      placeholder="Search Anime"
                      value={
                        animes
                          ? ` ${animes.id} // ${animes.name}`
                          : searchTermAnimes
                      }
                      onChange={(e) => filteredAnimes(e.target.value)}
                    />
                  </div>
                  {searchTermAnimes !== "" && !animes && (
                    <div className="dropdown">
                      <div className="dropdown-content">
                        <div>
                          {searchAnimes.map((anime) => (
                            <div
                              key={anime.id}
                              onClick={() => {
                                setAnimes(anime);
                                setEpisode(anime.videos.length + 1);
                                /// can i set teh value of the input from here
                              }}
                            >
                              {anime.name}
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="nice-form-group">
                    <label>Episode</label>
                    <input
                      onChange={(e) => setEpisode(e.target.value)}
                      value={episode}
                      type="text"
                      placeholder="You should first choose an anime"
                    />
                  </div>
                </div>
              </div>
            </section>
            <section>
              <div className="href-target" id="Main"></div>
              <div className="subtitle-header">
                <h1>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="feather feather-layers"
                  >
                    <polygon points="12 2 2 7 12 12 22 7 12 2" />
                    <polyline points="2 17 12 22 22 17" />
                    <polyline points="2 12 12 17 22 12" />
                  </svg>
                  SubTitle
                </h1>

                <Button
                  disable={noLanguagesLeft}
                  type="default"
                  onClick={addSubtitle}
                >
                  Add subtitle
                </Button>
              </div>

              <div className="subtitle-container">
                {subtitles.map((subtitle, index) => (
                  <div className="inner-subtitle-container" key={index}>
                    <div className="nice-form-group avatar">
                      <label>Language: </label>
                      <select
                        name={`language-${index}`}
                        onChange={handleLanguageChange(index)}
                      >
                        <option value="">None</option>
                        {languages
                          .filter(
                            (language) =>
                              !selectedLanguages.includes(language.value) ||
                              language.value === selectedLanguages[index]
                          )
                          .map((language) => (
                            <option key={language.value} value={language.value}>
                              {language.label}
                            </option>
                          ))}
                      </select>
                      <label>Subtitle: </label>
                      <input
                        type="file"
                        name={`subtitle-${index}`}
                        onChange={handleFileChange(index)}
                        accept=".srt,.vtt"
                      />
                    </div>
                    <Button
                      type="dangerous"
                      onClick={() => removeSubtitle(index)}
                    >
                      X{" "}
                    </Button>
                  </div>
                ))}
              </div>
            </section>
            <section>
              <div className="href-target" id="Main"></div>
              <div className="subtitle-header">
                <h1>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="feather feather-layers"
                  >
                    <polygon points="12 2 2 7 12 12 22 7 12 2" />
                    <polyline points="2 17 12 22 22 17" />
                    <polyline points="2 12 12 17 22 12" />
                  </svg>
                  Categories
                </h1>
              </div>

              <p>Add Categories</p>
              <div className="nice-form-group">
                <input
                  type="search"
                  placeholder="Search Categories"
                  onChange={(e) => setSearchCategories(e.target.value)}
                />
              </div>
              <div className="categories-container">
                {filteredCategories.map((category) => (
                  <div className="inner_categories_container" key={category.id}>
                    <div className="nice-form-group">
                      <input
                        type="checkbox"
                        id={`check-${category.id}`}
                        value={category.id}
                        onChange={handleCheckboxChange}
                        checked={categories.includes(category.id)} // Use categories here
                      />
                      <label htmlFor={`check-${category.id}`}>
                        {category.name}
                      </label>
                    </div>
                  </div>
                ))}
              </div>
            </section>

            <section>
              <div className="href-target" id="seo"></div>
              <h1>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="feather feather-layers"
                >
                  <polygon points="12 2 2 7 12 12 22 7 12 2" />
                  <polyline points="2 17 12 22 22 17" />
                  <polyline points="2 12 12 17 22 12" />
                </svg>
                SEO
              </h1>
              <p>Adding meta tags to main page</p>
              <div className="nice-form-group">
                <label>Title</label>
                <input
                  value={metaTitle}
                  onChange={(e) => setMetaTitle(e.target.value)}
                  type="text"
                  placeholder="Meta title"
                />
              </div>
              <div className="nice-form-group">
                <label>Description</label>
                <textarea
                  rows="5"
                  onChange={(e) => setMetaDescription(e.target.value)}
                  placeholder="meta desciption"
                  value={metaDescription}
                ></textarea>
              </div>
            </section>
          </main>
        </div>
      </div>
    </>
  );
}
