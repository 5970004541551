import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import "./MainPage.css";
import Card from "../components/card";
import { PieChart, Pie, Cell, ResponsiveContainer } from "recharts";
import { BarChart, Bar, XAxis, YAxis, Tooltip } from "recharts";
import Button from "../components/Button";
export default function MainPage() {
  const username = localStorage.getItem("username");
  const [statusInfo, setStatusInfo] = useState({
    videosCount: 0,
    categoriesCount: 0,
    animeCount: 0,
    usersCount: 0,
    categoriesWithoutVideos: 0,
    animesWithoutVideos: 0,
    todayWatchSessionsCount: 0,
    queueStatus: {
      waiting: 0,
      active: 0,
      completed: 0,
      failed: 0,
      delayed: 0,
      paused: 0,
    },
    serverIP: "localhost",
  });

  const [serverInfo, setServerInfo] = useState({
    diskSize: "0 GB",
    diskUsed: "0 GB",
    diskFree: "0 GB",
    diskUsagePercentage: "0",
    cpuUsagePercentage: "0",
    cpuUsagePerCore: [],
  });

  useEffect(() => {
    const fetchData = () => {
      fetch(`${process.env.REACT_APP_API}/status`, {
        credentials: "include",
      })
        .then((response) => response.json())
        .then((data) => setStatusInfo(data))
        .catch((error) => console.error("Error:", error));

      fetch(`${process.env.REACT_APP_API}/system-info`, {
        credentials: "include",
      })
        .then((response) => response.json())
        .then((data) => setServerInfo(data))
        .catch((error) => console.error("Error:", error));
    };

    fetchData(); // Fetch data immediately on mount

    const intervalId = setInterval(fetchData, 5000); // Fetch data every 15 seconds

    return () => clearInterval(intervalId); // Clean up the interval on unmount
  }, []);

  const diskData = [
    { name: "Used", value: parseFloat(serverInfo.diskUsagePercentage) },
    { name: "Free", value: 100 - parseFloat(serverInfo.diskUsagePercentage) },
  ];

  const COLORS = ["#FF8042", "#00C49F"];

  const cpuData = serverInfo.cpuUsagePerCore.map((usage, index) => ({
    core: `Core ${index + 1}`,
    usage: parseFloat(usage),
  }));

  return (
    <>
      <Header title={`Welcome back, ${username}`}>
        <Button url={`${process.env.REACT_APP_API}/queues`}>
          Manage Queues
        </Button>
      </Header>
      <div className="status">
        <div className="website">
          <div className="head_cards">
            <Card
              title="Videos"
              icon="video"
              data={statusInfo.videosCount}
              color="#f9b234"
            />
            <Card
              title="Categories"
              icon="category"
              data={statusInfo.categoriesCount}
              color="#3ecd5e"
            />
            <Card
              title="Animes"
              icon="anime"
              data={statusInfo.animeCount}
              color="#e44002"
            />
            <Card
              title="Users"
              icon="user"
              data={statusInfo.usersCount}
              color="#4c49ea"
            />
            <Card
              title="Target Videos"
              icon="video"
              data={500 - statusInfo.videosCount}
              color="#4c49ea"
            />
            <Card
              title="Categories Without Videos"
              icon="category"
              data={statusInfo.categoriesWithoutVideos}
              color="#ff6b6b"
            />
            <Card
              title="Animes Without Videos"
              icon="anime"
              data={statusInfo.animesWithoutVideos}
              color="#feca57"
            />
            <Card
              title="Today's Watch Sessions"
              icon="today"
              data={statusInfo.todayWatchSessionsCount}
              color="#54a0ff"
            />
          </div>
        </div>
        <div className="server">
          <h2>Server Information</h2>
          <div className="server-stats">
            <div className="disk-usage">
              <h3>Disk Usage</h3>
              <ResponsiveContainer width="100%" height={200}>
                <PieChart>
                  <Pie
                    data={diskData}
                    cx="50%"
                    cy="50%"
                    innerRadius={50}
                    outerRadius={80}
                    fill="#8884d8"
                    paddingAngle={0}
                    dataKey="value"
                  >
                    {diskData.map((entry, index) => (
                      <Cell
                        key={`cell-${index}`}
                        fill={COLORS[index % COLORS.length]}
                      />
                    ))}
                  </Pie>
                </PieChart>
              </ResponsiveContainer>
              <p>Total: {serverInfo.diskSize}</p>
              <p>
                Used: {serverInfo.diskUsed} ({serverInfo.diskUsagePercentage}%)
              </p>
              <p>Free: {serverInfo.diskFree}</p>
            </div>
            <div className="cpu-usage">
              <h3>CPU Usage</h3>
              <ResponsiveContainer width="100%" height={300}>
                <BarChart data={cpuData} layout="vertical">
                  <XAxis type="number" />
                  <YAxis dataKey="core" type="category" />
                  <Tooltip />
                  <Bar dataKey="usage" fill="#ff8042" />
                </BarChart>
              </ResponsiveContainer>
              <p>Overall CPU Usage: {serverInfo.cpuUsagePercentage}%</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
