import React from "react";
import "./header.css";
export default function Header({ title, children }) {
  return (
    <header>
      <h1>{title}</h1>
      <div>{children}</div>
    </header>
  );
}
