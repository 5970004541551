import React, { useContext, useEffect, useState } from "react";
import "./Login.css";
import axios from "axios";
import { NotificationContext } from "../../providers/NotificationProvider";
import { useNavigate } from "react-router-dom";
const api = axios.create({
  baseURL: `${process.env.REACT_APP_API}`,
  withCredentials: true,
});
const loginapi = axios.create({
  baseURL: `${process.env.REACT_APP_API}`,
  withCredentials: true,
});
export default function Login() {
  const [emailOrUsername, setEmailOrUsername] = useState("");
  const [password, setPassword] = useState("");
  const notify = useContext(NotificationContext);
  const navigate = useNavigate();
  useEffect(() => {
    api
      .get("/accounts/verify")
      .then((response) => {
        localStorage.setItem("avatar", response.data.data.avatar);
        localStorage.setItem("role", response.data.data.role);
        localStorage.setItem("username", response.data.data.username);
        navigate("/");
      })
      .catch(() => {});
  }, [navigate]);
  const handleEmailChange = (event) => {
    setEmailOrUsername(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };
  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      await loginapi.post("/accounts/login", {
        emailOrUsername,
        password,
      });
      notify("success", "Login Successful");
      navigate("/");
    } catch (error) {
      notify("error", error.response.data.message);
    }
  };
  return (
    <>
      <div id="login">
        <form>
          <h1>Login</h1>
          <input
            type="text"
            placeholder="Email Or Username"
            id="email"
            value={emailOrUsername}
            onChange={handleEmailChange}
          />
          <input
            type="password"
            placeholder="Password"
            id="password"
            value={password}
            onChange={handlePasswordChange}
          />
          <button onClick={handleLogin}>Log In</button>
        </form>
      </div>
    </>
  );
}
