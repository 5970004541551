import React, { useState, useContext, useEffect } from "react";
import axios from "axios";
import Header from "../../components/Header";
import Button from "../../components/Button";
import { NotificationContext } from "../../providers/NotificationProvider";
import { useNavigate } from "react-router-dom";
import "./AddCategory.css";
const api = axios.create({
  baseURL: `${process.env.REACT_APP_API}`,
});
export default function AddCategorie() {
  const notify = useContext(NotificationContext);
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [image, setImage] = useState(null);
  const [metaTitle, setMetaTitle] = useState("");
  const [metaDescription, setMetaDescription] = useState("");
  const [url, setUrl] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);

  // handle the url automatically
  useEffect(() => {
    const urlFromName = name
      .trim() // Remove leading and trailing spaces
      .toLowerCase()
      .replace(/\s+/g, "-") // Replace one or more spaces with a single hyphen
      .replace(/[^\w-]+/g, "-") // Replace non-alphanumeric and non-hyphen characters with a hyphen
      .replace(/--+/g, "-") // Replace two or more consecutive hyphens with a single hyphen
      .replace(/-+$/, ""); // Remove trailing hyphens
    setUrl(urlFromName);
  }, [name]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    setIsDisabled(true);

    const formData = new FormData();
    formData.append("name", name);
    formData.append("description", description);
    formData.append("url", url);
    formData.append("image", image);
    formData.append("metaTitle", metaTitle);
    formData.append("metaDescription", metaDescription);
    api
      .post("categories/add", formData)
      .then((response) => {
        notify("success", "Categorie Added Successfully");
        navigate("/categories");
      })
      .catch((error) => {
        notify("error", error.response.data.error);
      })
      .finally(() => {
        setIsLoading(false);
        setIsDisabled(false);
      });
  };

  return (
    <>
      <Header title="Add New Category">
        <Button
          loading={isLoading}
          disable={isDisabled}
          onClick={handleSubmit}
          icon="plus"
        >
          New Category
        </Button>
        <Button type="dark" url="/categories" icon="back" />
      </Header>
      <div className="mainlayout_content">
        <div className="demo-page">
          <div className="demo-page-navigation category">
            <div>
              <div className="category-review-img">
                <img
                  src={
                    image instanceof Blob
                      ? URL.createObjectURL(image)
                      : `${process.env.REACT_APP_API}/static/categories/default.webp`
                  }
                  alt=""
                />
                <h1>{name || "Category Name"}</h1>
              </div>
              <div className="category-content-review">
                <h2>Description</h2>
                <p>{description || "Write anything so this will be changed"}</p>
              </div>
            </div>
          </div>
          <main className="demo-page-content">
            <section>
              <div className="href-target" id="Main"></div>
              <h1>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="feather feather-layers"
                >
                  <polygon points="12 2 2 7 12 12 22 7 12 2" />
                  <polyline points="2 17 12 22 22 17" />
                  <polyline points="2 12 12 17 22 12" />
                </svg>
                Main
              </h1>
              <p>From here you could add a category</p>
              <div className="nice-form-group avatar">
                <label>Upload</label>
                <input
                  type="file"
                  accept="image/*"
                  name="image"
                  onChange={(e) => {
                    setImage(e.target.files[0]);
                  }}
                  required
                />
              </div>
              <div className="nice-form-group">
                <label>categorie name</label>
                <small>it should be unique</small>
                <input
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  type="text"
                  placeholder="Your name"
                  required
                />
              </div>
              <div className="nice-form-group">
                <label>Url</label>
                <small>it should be also unique</small>
                <input
                  value={url}
                  onChange={(e) => setUrl(e.target.value)}
                  type="text"
                  placeholder="the url"
                  required
                />
              </div>
              <div className="nice-form-group">
                <label>Description</label>
                <textarea
                  rows="5"
                  placeholder="Your description"
                  onChange={(e) => setDescription(e.target.value)}
                  value={description}
                ></textarea>
              </div>
            </section>
            <section>
              <div className="href-target" id="seo"></div>
              <h1>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="feather feather-layers"
                >
                  <polygon points="12 2 2 7 12 12 22 7 12 2" />
                  <polyline points="2 17 12 22 22 17" />
                  <polyline points="2 12 12 17 22 12" />
                </svg>
                SEO
              </h1>
              <p>Adding meta tags to main page</p>
              <div className="nice-form-group">
                <label>Title</label>
                <input
                  value={metaTitle}
                  onChange={(e) => setMetaTitle(e.target.value)}
                  type="text"
                  placeholder="Meta title"
                />
              </div>
              <div className="nice-form-group">
                <label>Description</label>
                <textarea
                  rows="5"
                  onChange={(e) => setMetaDescription(e.target.value)}
                  placeholder="meta desciption"
                  value={metaDescription}
                ></textarea>
              </div>
            </section>
          </main>
        </div>
      </div>
    </>
  );
}
